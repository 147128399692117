import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import i18n from './mixins/i18n'
import store from './store'

const app = createApp(App)
app.use(store)
app.mixin(i18n)
app.use(router)

app.mount('#app')
