import { createStore } from 'vuex'
import languages from "./languages";

const store = createStore({
  modules: {
    languages,
  },
  state: {
    currentLangNo: 0,
    userHasLoggedIn: false,
    userHasLoggedInContact: false,
  },
  getters: {
    language: (state) => {
      return state.languages.languages[state.currentLangNo]
  },
  },
  mutations: {
      GRANT_USER_SITE_ACCESS(state){
        state.userHasLoggedIn = true;
        console.log("tick all correct is locked is "+ state.userHasLoggedIn);
      },
      GRANT_USER_CONTACT_ACCESS(state){
        state.userHasLoggedInContact = true;
        console.log("tick all correct is locked is "+ state.userHasLoggedInContact);
      },

  },
  actions: {

  },
})

export default store;
