import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "KLÄDE",
    }
  },
  {
    path: '/new-range',
    name: 'new-range',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewRangeView.vue'),
    meta: {
      title: "New Range - KLÄDE",
    }
  },
  {
    path: '/our-range',
    name: 'our-range',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurRangeView.vue'),
    meta: {
      title: "Our Range - KLÄDE",
    }
  },
  {
    path: '/our-story',
    name: 'our-story',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurStoryView.vue'),
    meta: {
      title: "Our Story - KLÄDE",
    }
  },
  {
    path: '/our-campaigns',
    name: 'our-campaigns',
    component: () => import(/* webpackChunkName: "about" */ '../views/OurCampaignsView.vue'),
    meta: {
      title: "Our Campaigns - KLÄDE",
    }
  },
  {
    path: '/people',
    name: 'people',
    component: () => import(/* webpackChunkName: "about" */ '../views/PeopleView.vue'),
    meta: {
      title: "People - KLÄDE",
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: "Contact - KLÄDE",
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
