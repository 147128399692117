<template>
	<button class="absolute lg:static right-20 sm:right-28 top-1/2 -translate-y-1/2 lg:translate-y-0">
		<img
			v-for="(language, index) in languages"
			:key="index"
			class="selectorFlag"
			:class="{ ['selectorFlag--selected']: language.langNo === getCurrentLangNo }"
			:src="getFlagImage(language.image)"
			alt=""
			@click="showLangSelector"
		/>
	</button>
	<div class="langList bg-blue" :class="{ ['langList--active']: langListActive }">
		<img @click="hideLangSelector" class="langListClose" src="../assets/img/white-cross.png" alt="" />
		<p class="font-courierNew font-bold text-base text-white mb-4">{{ langG.langChoose }}</p>
		<ul>
			<li v-for="(language, index) in languages" :key="index" class="langListItem" @click="handleLangSelectorClick(index)">
				<img class="mr-2" :src="getFlagImage(language.image)" alt="" />
				<p class="font-courierNew font-bold text-base text-white">{{ langG.langSelectorItems[index].text }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			langListActive: false,
			languages: [
				{
					lang: "English",
					text: "English EN",
					langNo: 0,
					image: "english.svg",
				},
				{
					lang: "Spanish",
					text: "Español ES",
					langNo: 1,
					image: "spanish.svg",
				},
				{
					lang: "Colombian Spanish",
					text: "Español Colombiano ESCO",
					langNo: 2,
					image: "spanish-col.svg",
				},
				{
					lang: "French",
					text: "Français FR",
					langNo: 3,
					image: "french.svg",
				},
				{
					lang: "German",
					text: "Deutsch DE",
					langNo: 4,
					image: "german.svg",
				},
				{
					lang: "Italian",
					text: "Italiano IT",
					langNo: 5,
					image: "italian.svg",
				},
				{
					lang: "Netherlands",
					text: "Nederlands NL",
					langNo: 6,
					image: "dutch.svg",
				},
				{
					lang: "Japanese",
					text: "日本 JA",
					langNo: 7,
					image: "japanese.svg",
				},
				{
					lang: "Korean",
					text: "韓国語 KO",
					langNo: 8,
					image: "korean.svg",
				},
				{
					lang: "Polish",
					text: "Polski PL",
					langNo: 9,
					image: "polish.svg",
				},
				{
					lang: "Portuguese",
					text: "Português PT",
					langNo: 10,
					image: "portuguese.svg",
				},
				{
					lang: "Brazilian Portuguese",
					text: "Portugues do Brasil PTBR",
					langNo: 11,
					image: "portuguese-bra.svg",
				},
				{
					lang: "Russian",
					text: "Pусский RU",
					langNo: 12,
					image: "russian.svg",
				},
				{
					lang: "Turkish",
					text: "турецкий TK",
					langNo: 13,
					image: "turkish.svg",
				},
				{
					lang: "Simplified Chinese",
					text: "简体中文 ZHCN",
					langNo: 14,
					image: "chinese-simplified.svg",
				},
				{
					lang: "Taiwanese Mandarin",
					text: "國語 ZHTW",
					langNo: 15,
					image: "taiwanese.svg",
				},
				{
					lang: "Hebrew",
					text: "Hebrew HE",
					langNo: 16,
					image: "hebrew.png",
				},
			],
		};
	},
	name: "LangSelector",
	components: {},
	computed: {
		getCurrentLangNo() {
			return this.$store.state.currentLangNo;
		},
	},
	methods: {
		hideLangSelector() {
			this.langListActive = false;
		},
		showLangSelector() {
			this.langListActive = true;
		},
		handleLangSelectorClick(langNo) {
			this.hideLangSelector();
			this.$store.state.currentLangNo = langNo;
		},
		getFlagImage(imageName) {
			return require("../assets/img/flags/" + imageName);
		},
	},
};
</script>

<style scoped>
.selectorFlag {
	display: none;
}

.selectorFlag--selected {
	display: block;
}

.langList {
	position: absolute;
	right: 0;
	opacity: 0;
	top: -200vh;
	padding: 50px 20px 20px;
	z-index: 2;
	transition: all 1s ease;
}

.langList--active {
	top: 0;
	opacity: 1;
}

.langListClose {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.langListItem {
	display: flex;
	margin-bottom: 10px;
	cursor: pointer;
	width: fit-content;
}

@media (max-width: 768px) {
	.langList {
		width: 100vw;
		height: 100vh;
	}
}
</style>
