<template>
  <div>
    <router-link to="/new-range-view">
      <div class="bg-blue w-full text-center font-bebas text-xl text-white pt-2 pb-1 leading-5">{{langR.banner}}</div>
    </router-link>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 home-left bg-no-repeat bg-cover flex flex-col justify-center pr-5">
        <!-- Apply RTL styling only to specific headings in home-left -->
        <h4 :class="{ 'rtl': isHebrew }" class="text-white text-5xl md:text-7xl font-bebas text-right">{{langR.leftHeader1}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-white text-5xl md:text-7xl font-bebas text-right">{{langR.leftHeader2}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-white text-5xl md:text-7xl font-bebas text-right">{{langR.leftHeader3}}</h4>
      </div>
      <div class="w-full lg:w-1/2 home-right flex flex-col justify-center pr-5 lg:pl-5">
        <!-- Apply RTL styling only to specific headings in home-right -->
        <h4 :class="{ 'rtl': isHebrew }" class="text-blue text-5xl md:text-7xl font-bebas text-right lg:text-left hidden xl:invisible xl:block">{{langR.rightHeader1}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-blue text-5xl md:text-7xl font-bebas text-right lg:text-left invisible">{{langR.rightHeader2}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-blue text-5xl lg:text-7xl font-bebas text-right lg:text-left invisible">{{langR.rightHeader3}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-blue text-5xl md:text-7xl font-bebas text-right lg:text-left">{{langR.rightHeader4}}</h4>
        <h4 :class="{ 'rtl': isHebrew }" class="text-blue text-5xl md:text-7xl font-bebas text-right lg:text-left">{{langR.rightHeader5}}</h4>
      </div>  
    </div>
  </div>
</template>

<script>


export default {
  // name: 'HomeView',
  created() {
        if (this.$route.hash === '#home') {
            return
        }
  },
  components: {},
  computed: {
    getCurrentLangNo() {
      return this.$store.state.currentLangNo;
    },
    isHebrew() {
      return this.getCurrentLangNo === 16; // Adjust the langNo based on your data
    },
  },
}
</script>

<style scoped>
  .home-left {
    background-image: url("../assets/img/home-left.png");
    height: calc(100vh - 101px);
  }

  .home-right {
    height: calc(100vh - 101px);
  }

  @media (max-width: 768px){
    .home-left {      
      height: 50vh;
    }

    .home-right {
      height: 30vh;
    }
  }

 .rtl {
  direction: rtl;
 }
</style>
