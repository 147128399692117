const languages = {
  state: {
    languages: [
      //EN 
      {
        language: "en",
        languageName: "English",
        languageFlag: "img/english.png",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "New Range" },
            { item: "Our Range" },
            { item: "Our Story" },
            { item: "Our Campaigns" },
            { item: "People" },
            { item: "Contact" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Enter password: ",
          },
        },
        route: {
          "/": {
            banner: "Preview Our New Range",
            leftHeader1: "Comfort.",
            leftHeader2: "Elegance.",
            leftHeader3: "Innovation.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Scandinavian Style.",
            rightHeader5: "Anywhere.",
          },
          "/new-range": {
            banner: "Coming soon",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Versatile comfort<br class='md:hidden' /> for<br class='hidden md:block'/> year-round adventures</h4>",
            p1: "Look out for our upcoming winter apparel range. At the high end of winter wear, our new line uses the most advanced materials to keep wearers comfortable and flexible outdoors.",
            p2: "Our own waterproof, breathable outer fabric has all the versality of a shell jacket, while the finest pure down feathers provide the warmth and comfort of a fully insulated jacket.",
            p3: "Suitable for all outdoor pursuits and professions.",
          },
          "/our-range": {
            heroHeader: "Our Range",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>Visit our online store to<br /> see our full range of clothing<br /> and accessories.</p>",
            heroBtn: "Visit Store",
            shippingHeader: "Shipping, Logistics & Supplies",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Our Story",
            heroP:
              "KLÄDE is the result of a collaboration between two skiing enthusiasts, Didier Rochereau and Berndt Långström, who joined forces ten years ago to realise their vision of a new clothing brand. Their goal was simple: to bring durable, premium clothes and accessories to a broader, more diverse market.",
            section1P1:
              "In the early days of KLÄDE, Didier designed distinctive, on-trend items while Berndt, in the modest surroundings of his garage, used his deep knowledge of materials and fabrics to make Didier’s designs a reality. Together they created stylish but robust clothing that wouldn’t fall victim to the throwaway habits of fast fashion.",
            section1P2:
              "Combining comfort and style, KLÄDE’s early product lines were striking enough to attract local support from models and athletes. Media attention and an expanding consumer base soon followed, helping to grow the brand into the major player it is today.",
            section2Header: "“Combining comfort and style…”",
            section2P1:
              "We now operate from a manufacturing and office site in Herlev, Denmark, as well as from our flagship store in central Copenhagen and several other stores across all the Scandinavian capitals.",
            section2P2: "But this is only the beginning of our journey. ",
            section2P3:
              "In the coming years, we will be widening our scope to include new areas of research and development, furthering our mission to provide stylish, practical clothing to discerning consumers around the world.",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE IN<br /> THE US </h4><p class='font-courierNew font-bold text-base text-white'>Click on the map pins to view destinations we have visited.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1:
              "Join us as we bring our new winter apparel range to the USA!",
            section1P2:
              " Kläde brand ambassadors Ferdie Zorrilla and Holly Beatrice-Wells are touring some of the most popular ski resorts in the United States to promote our new range. At each stop, for a limited time only, we’ll be stocking our latest items in local ski wear stores. ",
            section1P3:
              " Launching the campaign in Aspen, Colorado, our brand ambassadors went on to appear in the nearby Beaver Creek and Steamboat resorts, before heading to Utah, Wyoming and California. Most recently, they appeared at the Okemo Mountain Resort in Vermont. ",
            section1Heading1: "Next stop:",
            section1Heading2: "sugarloaf, Maine.",
            section2P1:
              "Check here for updates, or follow our brand ambassadors on social media:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 hours ago</p>",
            section2LeftP4:
              "<p class='font-helvetica mt-3 cardLeftText'>What a way to begin our tour! Happy to partner with Kläde, a European brand that should be on your radar. Wearing their jackets DAILY. The hype train has left the station!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 hours ago</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>Farewell Aspen! It’s been a wild ride!! Can’t wait to hit the slopes in Maine! See u there?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Follow",
          },
          "/people": {
            heroHeader: "Our People",
            heroP: "Click on each person’s image for more information",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Sales & Marketing Director",
                  "A native of Helsinki, Tiiu was a member of the Royal Swedish Ballet, before retraining as a salesperson. She holidayed in Hemsedal, where Didier was her ski instructor. Tiiu went on to work in Växjö to help improve the online store at Outnorth AB, a large retailer of Scandinavian outdoor clothing and equipment. Finding her feet quickly, she rose to lead the e-commerce operation after one year. When Didier contacted her about the opportunity to build the online presence of a new fashion company, Tiiu was ready to work in a smaller operation once again. She has since reinvented KLÄDE’s marketing and communications strategy and has shown herself to be adept at building relationships with customers and businesses.",
                  "Tiiu’s childhood hero was David Attenborough and today she is still a keen amateur naturalist with a special interest in birds.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Production Manager",
                  "Born in Copenhagen to Indian parents, Vijay studied mathematics at the University of Southern Denmark. After graduating, he worked at the Danish private label garment manufacturer United Textile Group (UTG), which specializes in green and sustainable production methods. When he saw that a progressive clothing designer was looking for someone with manufacturing experience, Vijay seized the chance to gain more autonomy and take a more creative role in an up-and-coming company. Only 10 people were working for KLÄDE when Vijay joined, but since then he has been one of our key advocates for growth and expansion, always searching for overlooked or hidden opportunities.",
                  "In his spare time, Vijay enjoys puzzles and board games. ",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Head of R&D and Co-Founder",
                  "Originally from Malmö, Sweden, Berndt moved up north to Luleå University to study Materials Science. It was there that he had the idea to create materials that would keep wearers warm and dry while being light and flexible enough to allow strenuous outdoor activity. On a ski trip to Hemsedal, Norway, he met Didier – and KLÄDE was born. Berndt’s post-graduate project led to the creation of a flexible, lightweight, breathable, but waterproof fabric. With KLÄDE’s new winter range, Berndt has put that knowledge to good use by designing practical, stylish clothing for a range of cold-weather pursuits.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Finance Director",
                  "Luke studied Finance as part of his Business Administration BBA at BI Norwegian Business School, before taking some time out to pursue his passion for travel. While in Singapore, he found work as a Junior Business Analyst at Deloitte and stayed there for three years. Missing family and home in South Korea, Luke returned there with the expectation of working for another multinational consultancy firm, until a disruptive new clothing company in Denmark captured his interest. Relishing the chance to work as part of a small team, Luke’s globally minded yet detail-driven approach has been an invaluable asset to KLÄDE’s growth. ",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "e-Commerce Sales Manager ",
                  "A native of Aalborg, Denmark, Alma was in fashion sales in Copenhagen. She retrained, got a Master of Science in Business Administration, Information Systems and E-business from the Copenhagen Business School and took a job a couple of years ago with KLÄDE, where she is responsible for their e-commerce channel.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "President, Head of Design and Co-Founder",
                  "Didier was raised by his French father and Moroccan mother in Chamonix in the Alps, where he learnt to ski soon after he learnt to walk. He earned a Diploma in Fashion Design at the esteemed fashion school ESMOD in Paris, France, and met his soon-to-be business partner while working as a ski instructor in Norway. Didier lives in Copenhagen and still enjoys hitting the slopes up in the Alps.",
                ],
              },
              ],
          },
          "/contact": {
            formHeader: "Contact us",
            formP1: "Complete the form below.",
            formEmail: "Your email",
            formSelect: "Select your option",
            formOption1: "Option 1",
            formOption2: "Option 2",
            formOption3: "Option 3",
            formSubject: "Subject",
            formTextarea: "Comments",
            formSubmit: "Submit",
          },
        },
      },
      // placeholder second land to test lang switch
      //ES done
      {
        language: "es",
        languageName: "Spanish",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          "navItems": [
            { item: "Nueva línea" },
            { item: "Nuestra línea" },
            { item: "Nuestra historia" },
            { item: "Nuestras campañas" },
            { item: "Personal" },
            { item: "Contacto" }
          ]
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Introduce la contraseña: "
          },
        },
        route: {
          "/": {
            banner: "Avance de la nueva línea",
            leftHeader1: "Comodidad.",
            leftHeader2: "Elegancia.",
            leftHeader3: "Innovación.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Estilo escandinavo.",
            rightHeader5: "En cualquier parte."
          },
          "/new-range": {
            banner: "Próximamente",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Comodidad versátil<br class='md:hidden' /> para<br class='hidden md:block'/> las aventuras de todo el año</h4>",
            p1: "Atención a nuestra futura línea de ropa de invierno. A la vanguardia de la ropa de invierno, nuestra nueva línea utiliza los materiales más avanzados para proporcionar comodidad y flexibilidad al aire libre.",
            p2: "Nuestro propio tejido externo, impermeable y transpirable, tiene la versatilidad de un chubasquero que, unido al plumón más exquisito y puro, proporciona la calidez y comodidad de un plumífero.",
            p3: "Adecuada para todas las actividades y profesiones al aire libre."
          },
          "/our-range": {
            heroHeader: "Nuestra línea",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Visita nuestra tienda online para<br /> ver todas nuestras líneas de ropa<br /> y accesorios.</p>",
            heroBtn: "Visitar la tienda",
            shippingHeader: "Envíos, logística y suministros",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Nuestra historia",
            heroP: "KLÄDE es el resultado de una colaboración entre dos entusiastas del esquí, Didier Rochereau y Berndt Långström, quienes unieron fuerzas hace diez años para hacer realidad su visión sobre una nueva marca de ropa. Su objetivo era simple: proporcionar ropa y complementos duraderos y de la mejor calidad a un mercado más amplio y diverso.",
            section1P1: "En los inicios de KLÄDE, Didier diseñó artículos distintivos y modernos mientras, en el modesto entorno de su garaje, Berndt usaba su profundo conocimiento en materiales y tejidos para que los diseños de Didier cobraran vida. Juntos crearon ropa elegante pero resistente para que no se volviera víctima de los hábitos desechables de la moda rápida.",
            section1P2: "Al combinar comodidad y estilo, las primeras líneas de productos de KLÄDE eran lo bastante llamativas para atraer el apoyo local de modelos y atletas. La atención de los medios y una base cada vez mayor de clientes fueron los pasos siguientes, lo que los ayudó a que la marca adoptara el papel importante que tiene hoy en día.",
            section2Header: "«Al combinar comodidad y estilo...».",
            section2P1: "Ahora operamos desde nuestra planta de producción y nuestro edificio de oficinas en Herlev (Dinamarca), así como desde nuestra tienda insignia en el centro de Copenhague y varias tiendas más en distintas capitales escandinavas.",
            section2P2: "Sin embargo, esto es solo el principio del camino. ",
            section2P3: "En los próximos años, ampliaremos nuestro alcance para incluir nuevas áreas de investigación y desarrollo, y continuaremos nuestra misión para proporcionar ropa práctica y elegante que atraiga a clientes de todo el mundo."
          },
          "/our-campaigns": {
            "heroContent": "<h4 class='text-white font-bebas text-5xl'>KLÄDE EN EE. UU. </h4><p class='font-courierNew font-bold text-base text-white'>Haz clic en los puntos marcados en el mapa para ver los destinos que hemos visitado.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Únete a nosotros mientras presentamos nuestra nueva línea de ropa de invierno en EE. UU.",
            section1P2: " Los embajadores de KLÄDE, Ferdie Zorrilla y Holly Beatrice-Wells, están visitando algunas de las estaciones de esquí más famosas de Estados Unidos para promocionar la nueva línea. En cada parada, solo por un tiempo limitado, podréis conseguir nuestros nuevos artículos en las tiendas locales de ropa de esquí. ",
            section1P3: " Tras lanzar nuestra campaña en Aspen (Colorado), nuestros embajadores de la marca hicieron una parada en las estaciones cercanas de Beaver Creek y Steamboat antes de dirigirse a Utah, Wyoming y California. Por último, visitaron la estación de esquí Okemo Mountain en Vermont. ",
            section1Heading1: "Siguiente parada:",
            section1Heading2: "Sugarloaf (Maine).",
            section2P1: "Presta atención a las actualizaciones desde aquí o sigue a nuestros embajadores en las redes sociales:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • hace 8 horas</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>¡Vaya comienzo de viaje! Me encanta colaborar con KLÄDE, una marca europea que deberíais tener en vuestro radar. Llevo sus chaquetas TODOS LOS DÍAS. ¡El tren del hype ha salido de la estación! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • hace 2 horas</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>¡Adiós, Aspen! ¡Ha sido una parada de locos! ¡No puedo esperar a bajar por las pistas de Maine! ¿Nos vemos allí?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span></p>",
            section2CardBtn: "Seguir"
          },
          "/people": {
            heroHeader: "Nuestro personal",
            heroP: "Para obtener más información, haz clic en la imagen de cada persona",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Director de Ventas y Marketing",
                  "Originario de Helsinki, Tiiu fue miembro del Ballet Real Sueco, antes de volver a capacitarse como vendedor. Pasó sus vacaciones en Hemsedal, donde Didier era su instructor de esquí. Tiiu pasó a trabajar en Växjö para ayudar a mejorar la tienda online de Outnorth AB, un gran minorista de ropa y equipos para exteriores escandinavos. Al encontrar su lugar rápidamente, ascendió para liderar la operación de comercio electrónico después de un año. Cuando Didier la contactó sobre la oportunidad de construir la presencia en línea de una nueva empresa de moda, Tiiu estaba lista para trabajar en una operación más pequeña una vez más. Desde entonces, ha reinventado la estrategia de marketing y comunicación de KLÄDE y ha demostrado ser experta en establecer relaciones con clientes y empresas.",
                  "El héroe de la infancia de Tiiu fue David Attenborough y hoy sigue siendo una entusiasta naturalista aficionada con especial interés en las aves.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Production Manage",
                  "Nacido en Copenhague de padres indios, Vijay estudió matemáticas en la Universidad del Sur de Dinamarca. Después de graduarse, trabajó en el fabricante danés de ropa de marca privada United Textile Group (UTG), que se especializa en métodos de producción ecológicos y sostenibles. Cuando vio que un diseñador de ropa progresista buscaba a alguien con experiencia en fabricación, Vijay aprovechó la oportunidad para ganar más autonomía y asumir un papel más creativo en una empresa prometedora. Solo 10 personas trabajaban para KLÄDE cuando Vijay se unió, pero desde entonces ha sido uno de nuestros principales defensores del crecimiento y la expansión, siempre buscando oportunidades ocultas o pasadas por alto.",
                  "En su tiempo libre, a Vijay le gustan los rompecabezas y los juegos de mesa.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Jefe de I + D y cofundador",
                  "Originario de Malmö, Suecia, Berndt se mudó al norte, a la Universidad de Luleå, para estudiar Ciencias de los Materiales. Fue allí donde tuvo la idea de crear materiales que mantuvieran a los usuarios abrigados y secos y al mismo tiempo fueran lo suficientemente ligeros y flexibles para permitir actividades extenuantes al aire libre. En un viaje de esquí a Hemsedal, Noruega, conoció a Didier y nació KLÄDE. El proyecto de posgrado de Berndt condujo a la creación de un tejido flexible, ligero, transpirable pero impermeable. Con la nueva gama de invierno de KLÄDE, Berndt ha aprovechado ese conocimiento al diseñar ropa práctica y elegante para una variedad de actividades en climas fríos.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Director de finanzas",
                  "Luke estudió Finanzas como parte de su BBA en Administración de Empresas en BI Norwegian Business School, antes de tomarse un tiempo para dedicarse a su pasión por los viajes. Mientras estuvo en Singapur, encontró trabajo como analista de negocios junior en Deloitte y permaneció allí durante tres años. Luke, que extrañaba a su familia y su hogar en Corea del Sur, regresó allí con la expectativa de trabajar para otra consultora multinacional, hasta que una nueva y disruptiva empresa de ropa en Dinamarca captó su interés. Al disfrutar de la oportunidad de trabajar como parte de un equipo pequeño, el enfoque global pero centrado en los detalles de Luke ha sido un activo invaluable para el crecimiento de KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Gerente de Ventas de Comercio Electrónico",
                  "Originaria de Aalborg, Dinamarca, Alma trabajaba en ventas de moda en Copenhague. Se volvió a capacitar, obtuvo una Maestría en Ciencias en Administración de Empresas, Sistemas de Información y Comercio Electrónico de la Escuela de Negocios de Copenhague y hace un par de años aceptó un trabajo en KLÄDE, donde es responsable de su canal de comercio electrónico.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Presidente, jefe de diseño y cofundador",
                  "Didier fue criado por su padre francés y su madre marroquí en Chamonix, en los Alpes, donde aprendió a esquiar poco después de aprender a caminar. Obtuvo un Diploma en Diseño de Moda en la prestigiosa escuela de moda ESMOD en París, Francia, y conoció a su futuro socio comercial mientras trabajaba como instructor de esquí en Noruega. Didier vive en Copenhague y todavía disfruta de las pistas de los Alpes.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Contáctanos",
            formP1: "Completa el siguiente formulario.",
            formEmail: "Tu email",
            formSelect: "Selecciona una opción",
            formOption1: "Opción 1",
            formOption2: "Opción 2",
            formOption3: "Opción 3",
            formSubject: "Asunto",
            formTextarea: "Comentarios",
            formSubmit: "Enviar"
          },
        },
      },
      //ESCO done
      {
        language: "esco",
        languageName: "Colombian Spanish",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Nueva gama" },
            { item: "Nuestra gama" },
            { item: "Nuestra historia" },
            { item: "Nuestras campañas" },
            { item: "Personas" },
            { item: "Contacto" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Introduzca la contraseña:",
          },
        },
        route: {
          "/": {
            banner: "Vista previa de nuestra nueva gama",
            leftHeader1: "Comodidad.",
            leftHeader2: "Elegancia.",
            leftHeader3: "Innovación.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Estilo escandinavo.",
            rightHeader5: "En cualquier lugar.",
          },
          "/new-range": {
            banner: "Próximamente",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Comodidad versátil<br class='md:hidden' /> para<br class='hidden md:block'/> las aventuras de todo el año</h4>",
            p1: "No pierda de vista nuestra próxima gama de ropa para invierno. Al ser vestimenta de alta gama para invierno, nuestra nueva colección utiliza los materiales más avanzados para mantener a los usuarios cómodos y flexibles al aire libre.",
            p2: "Nuestra propia tela transpirable y resistente al agua tiene toda la versatilidad de una jaqueta de exterior, mientras que las finas plumas proporcionan la calidez y comodidad de una jaqueta completamente aislada.",
            p3: "Adecuada para todas las actividades y profesiones al aire libre.",
          },
          "/our-range": {
            heroHeader: "Nuestra gama",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>Visite nuestra tienda en línea<br /> para ver la gama completa de ropa<br /> y accesorios.</p>",
            heroBtn: "Visitar la tienda",
            shippingHeader: "Envíos, logística y suministros",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Nuestra historia",
            heroP:
              "KLÄDE se originó a partir de la colaboración entre dos entusiastas del esquí, Didier Rochereau y Berndt Långström, quienes unieron fuerzas hace diez años para hacer realidad su visión de una nueva marca de ropa. Su objetivo era simple: ofrecer ropa y accesorios duraderos y de calidad superior a un mercado más amplio y diverso.",
            section1P1:
              "En los primeros días de KLÄDE, Didier diseñó artículos característicos y a la moda, mientras que Berndt, en el modesto entorno de su garaje, utilizó su amplio conocimiento en materiales y telas para traer al mundo los diseños de Didier. Juntos, crearon ropa resistente y con estilo, que no terminaría en el basurero como la moda rápida.",
            section1P2:
              "Al combinar comodidad y estilo, las primeras colecciones de productos de KLÄDE fueron lo suficientemente impresionantes como para atraer el apoyo local de modelos y atletas. La atención de los medios y una creciente base de consumidores siguió poco después, lo que ayudó a desarrollar la marca hasta ser la importante empresa que es ahora.",
            section2Header: "«Combinar comodidad y estilo»",
            section2P1:
              "Ahora operamos en instalaciones de fabricación y oficinas en Herlev, Dinamarca, así como en nuestra emblemática tienda en el centro de Copenhague y varias otras tiendas en las capitales escandinavas.",
            section2P2: "Pero este es solo el inicio de nuestra travesía. ",
            section2P3:
              "En los próximos años, ampliaremos nuestro alcance para incluir áreas de investigación y desarrollo, con lo que profundizaremos en nuestra misión de ofrecer ropa práctica y con estilo para nuestros clientes distinguidos en todo el mundo.",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE EN<br /> EE. UU. </h4><p class='font-courierNew font-bold text-base text-white'>Haga clic en los alfileres en el mapa para ver los destinos que hemos visitado.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek y Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1:
              "¡Acompáñenos conforme traemos nuestra nueva gama de indumentaria para invierno a Estados Unidos!",
            section1P2:
              " Los embajadores de la marca Kläde, Ferdie Zorrilla y Holly Beatrice-Wells, están de gira por algunos de los centros de esquí más populares en Estados Unidos para promover nuestra nueva gama. En cada parada, únicamente por tiempo limitado, estarán disponibles nuestros artículos más recientes en las tiendas locales de esquí.",
            section1P3:
              "En el lanzamiento de la campaña en Aspen, Colorado, nuestros embajadores de la marca se presentaron en los centros próximos de Beaver Creek y Steamboat, antes de dirigirse a Utah, Wyoming y California. Más recientemente, estuvieron en el centro de Okemo Mountain en Vermont.",
            section1Heading1: "Siguiente parada:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1:
              "Vea aquí las novedades y siga a nuestros embajadores de la marca en las redes sociales:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • Hace 8 horas</p>",
            section2LeftP4:
              "¡Qué forma de iniciar nuestro tour! Estamos encantados de asociarnos con Kläde, una marca europea que debería estar en su radar. Uso a DIARIO sus jaquetas. ¡¡¡El tren del alboroto salió de la estación!!! <span class='text-green'>#EstiloKläde #TourKläde</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 hours ago</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>¡Adiós, Aspen! ¡¡Fue un recorrido salvaje!! ¡No puedo esperar a deslizarme por las pendientes en Maine! ¿Nos vemos allá?<span class='text-green'>#EstiloKläde #nieve #montañas#Aspen #TourKläde</span</p>",
            section2CardBtn: "Seguir",
          },
          "/people": {
            heroHeader: "Nuestro personal",
            heroP: "Haga clic en la imagen de cada persona para obtener más información.",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Director de Ventas y Marketing",
                  "Natural de Helsinki, Tiiu fue miembro del Ballet Real Sueco, antes de reciclarse como vendedora. Pasó sus vacaciones en Hemsedal, donde Didier fue su profesor de esquí. Tiiu se trasladó a Växjö para ayudar a mejorar la tienda en línea de Outnorth AB, un gran minorista escandinavo de ropa y equipamiento para actividades al aire libre. Se adaptó rápidamente y, al cabo de un año, pasó a dirigir las operaciones de comercio electrónico. Cuando Didier se puso en contacto con ella para ofrecerle la oportunidad de crear la presencia en línea de una nueva empresa de moda, Tiiu estaba dispuesta a volver a trabajar en una operación más pequeña. Desde entonces ha reinventado la estrategia de marketing y comunicación de KLÄDE y ha demostrado ser experta en establecer relaciones con clientes y empresas.",
                  "El héroe de la infancia de Tiiu era David Attenborough y hoy sigue siendo una naturalista aficionada con especial interés por las aves.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Jefe de producción",
                  "Nacido en Copenhague de padres indios, Vijay estudió Matemáticas en la Universidad del Sur de Dinamarca. Tras licenciarse, trabajó en el fabricante danés de ropa de marca blanca United Textile Group (UTG), especializado en métodos de producción ecológicos y sostenibles. Cuando vio que un diseñador de ropa progresista buscaba a alguien con experiencia en fabricación, Vijay aprovechó la oportunidad de ganar más autonomía y asumir un papel más creativo en una empresa emergente. Cuando Vijay se incorporó a KLÄDE solo trabajaban 10 personas, pero desde entonces ha sido uno de nuestros principales defensores del crecimiento y la expansión, siempre en busca de oportunidades pasadas por alto u ocultas.",
                  "En su tiempo libre, Vijay disfruta con los rompecabezas y los juegos de mesa.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Director de I+D y Cofundador",
                  "Natural de Malmö (Suecia), Berndt se trasladó al norte, a la Universidad de Luleå, para estudiar Ciencias de los Materiales. Fue allí donde se le ocurrió la idea de crear materiales que mantuvieran a los usuarios calientes y secos y, al mismo tiempo, fueran lo bastante ligeros y flexibles como para permitir una actividad extenuante al aire libre. En un viaje de esquí a Hemsedal (Noruega) conoció a Didier, y así nació KLÄDE. El proyecto de posgrado de Berndt desembocó en la creación de un tejido flexible, ligero, transpirable e impermeable. Con la nueva gama de invierno de KLÄDE, Berndt ha puesto en práctica sus conocimientos diseñando prendas prácticas y elegantes para diversas actividades en climas fríos.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Director Financiero",
                  "Luke estudió Finanzas como parte de su BBA de Administración de Empresas en la BI Norwegian Business School, antes de tomarse un tiempo para dedicarse a su pasión por los viajes. Durante su estancia en Singapur, encontró trabajo como analista empresarial junior en Deloitte, donde permaneció tres años. Tras echar de menos a su familia y su hogar en Corea del Sur, Luke regresó allí con la esperanza de trabajar para otra consultora multinacional, hasta que una nueva empresa de ropa disruptiva en Dinamarca captó su interés. El enfoque global y detallista de Luke, que disfruta de la oportunidad de trabajar en un equipo pequeño, ha sido un activo inestimable para el crecimiento de KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Director de ventas de comercio electrónico",
                  "Natural de Aalborg (Dinamarca), Alma trabajaba en ventas de moda en Copenhague. Se recicló, obtuvo un máster en Administración de Empresas, Sistemas de Información y Comercio Electrónico en la Copenhagen Business School y hace un par de años empezó a trabajar en KLÄDE, donde es responsable de su canal de comercio electrónico.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Presidente, Jefe de Diseño y Cofundador",
                  "Didier fue criado por su padre francés y su madre marroquí en Chamonix, en los Alpes, donde aprendió a esquiar poco después de aprender a andar. Se diplomó en diseño de moda en la prestigiosa escuela ESMOD de París (Francia) y conoció a la que pronto será su socia mientras trabajaba como monitor de esquí en Noruega. Didier vive en Copenhague y sigue disfrutando de las pistas de los Alpes.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Contáctenos",
            formP1: "Complete el siguiente formulario.",
            formEmail: "Su correo electrónico",
            formSelect: "Seleccione una opción.",
            formOption1: "Opción 1",
            formOption2: "Opción 2",
            formOption3: "Opción 3",
            formSubject: "Asunto",
            formTextarea: "Comentarios",
            formSubmit: "Enviar",
          },
        },
      },
      //FR done
      {
        language: "fr",
        languageName: "French",
        // languageFlag: "img/francais.png",
        updated: "08/07/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Nouvelle gamme" },
            { item: "Notre gamme" },
            { item: "Notre histoire" },
            { item: "Nos campagnes" },
            { item: "Équipe" },
            { item: "Contact" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Entrez le mot de passe: ",
          },
        },
        route: {
          "/": {
            banner: "Aperçu de notre nouvelle gamme",
            leftHeader1: "Confort.",
            leftHeader2: "Élégance.",
            leftHeader3: "Innovation.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Le style scandinave.",
            rightHeader5: "Partout.",
          },
          "/new-range": {
            banner: "Prochainement",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Un confort polyvalent<br class='md:hidden' /> pour vivre<br class='hidden md:block'/> des aventures toute l’année</h4>",
            p1: "Ne manquez pas notre prochaine gamme de vêtements d’hiver. Notre nouvelle ligne de vêtements d’hiver haut de gamme utilise les matériaux les plus avancés pour assurer le confort et la souplesse de chacun en extérieur.",
            p2: "Notre tissu extérieur imperméable et respirant présente toutes les caractéristiques d’une veste shell, tandis que les plumes de duvet les plus fines offrent la chaleur et le confort d’une veste entièrement isolée.",
            p3: "Idéal pour toutes les activités et professions de plein air.",
          },
          "/our-range": {
            heroHeader: "Notre gamme",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>Rendez-vous sur notre boutique en ligne pour<br /> voir notre gamme complète de vêtements<br /> et accessoires.</p>",
            heroBtn: "Visiter la boutique",
            shippingHeader: "Expédition, logistique et approvisionnement",
            shippingItems: [
              {
                item: "PostNord",
              },
              {
                item: "DPD",
              },
              {
                item: "DHL",
              },
              {
                item: "FREJA",
              },
              {
                item: "Steinweg",
              },
              {
                item: "UPS",
              },
            ],
          },
          "/our-story": {
            heroHeader: "Notre histoire",
            heroP:
              "KLÄDE est le fruit d’une collaboration entre deux passionnés de ski, Didier Rochereau et Berndt Långström, qui ont uni leurs forces il y a dix ans pour concrétiser leur vision d’une nouvelle marque de vêtements. Leur objectif était simple : proposer des vêtements et des accessoires durables et de qualité supérieure à un marché plus large et plus diversifié.",
            section1P1:
              "Dans les premiers temps de KLÄDE, Didier a conçu des pièces originales et très tendance, tandis que Berndt, dans l’espace modeste de son garage, a mis à profit ses connaissances approfondies des matériaux et des tissus pour donner vie aux créations de Didier. Ensemble, ils ont créé des vêtements élégants mais robustes, loin des tendances au gaspillage de la fast-fashion.",
            section1P2:
              "Combinant confort et style, les premières lignes de produits de KLÄDE étaient suffisamment percutantes pour s’attirer le soutien local de mannequins et d’athlètes. L’attention des médias et l’expansion de la base de clients n’ont pas tardé à suivre, contribuant à faire de la marque l’acteur majeur qu’elle est aujourd’hui.",
            section2Header: "« Combiner confort et style... »",
            section2P1:
              "Nous opérons aujourd’hui à partir d’un site de production et de bureaux situés à Herlev, au Danemark, ainsi que de notre boutique principale dans le centre de Copenhague et de plusieurs autres magasins dans toutes les capitales scandinaves.",
            section2P2: "Mais ce n’est que le début de notre aventure. ",
            section2P3:
              "Dans les années à venir, nous élargirons notre domaine d’activité pour inclure de nouveaux domaines de recherche et de développement, poursuivant ainsi notre mission qui consiste à proposer des vêtements élégants et pratiques aux consommateurs exigeants du monde entier.",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl'>KLÄDE AUX ÉTATS-UNIS </h4><p class='font-courierNew font-bold text-base text-white'>Cliquez sur les épingles de la carte pour voir les destinations où nous nous sommes rendus.</p>",
            mapItems: [
              {
                item: "Lac Tahoe",
              },
              {
                item: "Park City Mountain",
              },
              {
                item: "Aspen, Beaver Creek, Steamboat",
              },
              {
                item: "Jackson Hole",
              },
              {
                item: "Okemo Mountain",
              },
            ],
            section1P1:
              "Retrouvez notre nouvelle gamme de vêtements d’hiver aux États-Unis !",
            section1P2:
              " Les ambassadeurs de la marque Kläde, Ferdie Zorrilla et Holly Beatrice-Wells, sillonnent certaines des stations de ski les plus populaires des États-Unis pour promouvoir notre nouvelle gamme. À chaque étape, pour une durée limitée, nous proposerons nos derniers articles dans les magasins de vêtements de ski locaux. ",
            section1P3:
              " Après avoir lancé la campagne à Aspen, dans le Colorado, nos ambassadeurs se sont rendus dans les stations voisines de Beaver Creek et de Steamboat, avant de se rendre dans l’Utah, le Wyoming et la Californie. Plus récemment, ils ont posé leurs valises à Okemo Mountain Resort dans le Vermont. ",
            section1Heading1: "Prochaine escale :",
            section1Heading2: "Sugarloaf (Maine).",
            section2P1:
              "Informez-vous de nos mouvements ici ou suivez les ambassadeurs de notre marque sur les réseaux sociaux :",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • Il y a 8 heures</p>",
            section2LeftP4:
              "<p class='font-helvetica mt-3 cardLeftText'>Quelle belle façon de commencer notre tournée ! Je suis heureux de m’associer à Kläde, une marque européenne à ne pas manquer. Je porte leurs vestes TOUS LES JOURS. Le train du style vient d’entrer en gare !!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • Il y a 2 heures</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>Au revoir, Aspen ! Quelle aventure ! J’ai hâte de dévaler les pistes du Maine ! On se retrouve là-bas ?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Suivre",
          },
          "/people": {
            heroHeader: "Notre équipe",
            heroP:
              "Cliquez sur la photo de chaque personne pour plus d’informations",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Directeur Commercial & Marketing",
                  "Originaire d'Helsinki, Tiiu était membre du Ballet royal de Suède, avant de se reconvertir en vendeur. Elle passait ses vacances à Hemsedal, où Didier était son moniteur de ski. Tiiu a ensuite travaillé à Växjö pour contribuer à l'amélioration de la boutique en ligne d'Outnorth AB, un grand détaillant de vêtements et d'équipements d'extérieur scandinaves. Ayant rapidement trouvé ses marques, elle a pris la tête des opérations de commerce électronique après un an. Lorsque Didier l'a contactée au sujet de l'opportunité de développer la présence en ligne d'une nouvelle entreprise de mode, Tiiu était prête à travailler à nouveau dans une entreprise plus petite. Depuis, elle a réinventé la stratégie de marketing et de communication de KLÄDE et s’est montrée experte dans l’établissement de relations avec les clients et les entreprises.",
                  "Le héros d’enfance de Tiiu était David Attenborough et aujourd’hui, elle est toujours une naturaliste amateur passionnée avec un intérêt particulier pour les oiseaux.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Directeur de production",
                  "Né à Copenhague de parents indiens, Vijay a étudié les mathématiques à l'Université du Danemark du Sud. Après avoir obtenu son diplôme, il a travaillé chez le fabricant danois de vêtements de marque privée United Textile Group (UTG), spécialisé dans les méthodes de production vertes et durables. Lorsqu'il a vu qu'un créateur de vêtements progressiste recherchait quelqu'un ayant une expérience en fabrication, Vijay a saisi l'opportunité de gagner plus d'autonomie et de jouer un rôle plus créatif dans une entreprise en plein essor. Seules 10 personnes travaillaient chez KLÄDE lorsque Vijay nous a rejoint, mais depuis lors, il est l'un de nos principaux défenseurs de la croissance et de l'expansion, toujours à la recherche d'opportunités négligées ou cachées.",
                  "Dans ses temps libres, Vijay aime les puzzles et les jeux de société.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Responsable R&D et Co-Fondateur",
                  "Originaire de Malmö, en Suède, Berndt a déménagé dans le nord de l'Université de Luleå pour étudier la science des matériaux. C'est là qu'il a eu l'idée de créer des matériaux qui garderaient ceux qui les portent au chaud et au sec tout en étant suffisamment légers et flexibles pour permettre des activités de plein air intenses. Lors d'un voyage de ski à Hemsedal, en Norvège, il rencontre Didier – et KLÄDE est né. Le projet de troisième cycle de Berndt a conduit à la création d’un tissu flexible, léger, respirant mais imperméable. Avec la nouvelle gamme hiver de KLÄDE, Berndt a mis à profit ses connaissances en concevant des vêtements pratiques et élégants pour de nombreuses activités par temps froid.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Directeur des finances",
                  "Luke a étudié la finance dans le cadre de son BBA en administration des affaires à la BI Norwegian Business School, avant de prendre le temps de poursuivre sa passion pour les voyages. À Singapour, il a trouvé du travail en tant qu'analyste commercial junior chez Deloitte et y est resté trois ans. Ayant perdu sa famille et son domicile en Corée du Sud, Luke y est retourné avec l'espoir de travailler pour une autre société de conseil multinationale, jusqu'à ce qu'une nouvelle entreprise de vêtements perturbatrice au Danemark suscite son intérêt. Savourant la chance de travailler au sein d’une petite équipe, l’approche globale mais soucieuse du détail de Luke a été un atout inestimable pour la croissance de KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Responsable des ventes e-commerce",
                  "Originaire d'Aalborg, au Danemark, Alma travaillait dans les ventes de mode à Copenhague. Elle s'est reconvertie, a obtenu un Master en administration des affaires, systèmes d'information et commerce électronique à la Copenhagen Business School et a trouvé un emploi il y a quelques années chez KLÄDE, où elle est responsable du canal de commerce électronique.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Président, responsable du design et co-fondateur",
                  "Didier a été élevé par son père français et sa mère marocaine à Chamonix dans les Alpes, où il a appris à skier peu après avoir appris à marcher. Il a obtenu un diplôme en design de mode à la prestigieuse école de mode ESMOD à Paris, en France, et a rencontré son futur partenaire commercial alors qu'il travaillait comme moniteur de ski en Norvège. Didier vit à Copenhague et aime toujours dévaler les pistes des Alpes.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Nous contacter",
            formP1: "Complétez le formulaire ci-dessous.",
            formEmail: "Votre e-mail",
            formSelect: "Sélectionnez votre option",
            formOption1: "Option 1",
            formOption2: "Option 2",
            formOption3: "Option 3",
            formSubject: "Objet",
            formTextarea: "Commentaires",
            formSubmit: "Envoyer",
          },
        },
      },
      //DE done
      {
        language: "de",
        languageName: "German",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          "navItems": [
            { item: "Neue Kollektion" },
            { item: "Unser Sortiment" },
            { item: "Unsere Story" },
            { item: "Unsere Kampagnen" },
            { item: "Mitarbeiter" },
            { item: "Kontakt" }
          ]
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Passwort eingeben: "
          },
        },
        route: {
          "/": {
            banner: "Vorschau auf unsere neue Kollektion",
            leftHeader1: "Komfort.",
            leftHeader2: "Eleganz.",
            leftHeader3: "Innovation.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Skandinavischer Stil.",
            rightHeader5: "Überall."
          },
          "/new-range": {
            banner: "Demnächst",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Vielseitiger Komfort<br class='md:hidden' /> für<br class='hidden md:block'/> Abenteuer zu jeder Jahreszeit</h4>",
            p1: "Unsere Winterkollektion erscheint in Kürze. Die neue Modelinie ist im Luxussegment der Wintermode angesiedelt und macht sich die fortschrittlichsten Materialien zunutze, um ihren Trägern Komfort und grenzenlose Bewegungsfreiheit zu bieten.",
            p2: "Unser selbst entwickeltes wasserdichtes und atmungsaktives Außenmaterial ist so vielseitig wie eine Shelljacke, während die feinsten Daunenfedern die Wärme und den Komfort einer perfekt isolierten Jacke bieten.",
            p3: "Geeignet für alle Aktivitäten und berufliche Tätigkeiten im Freien."
          },
          "/our-range": {
            heroHeader: "Unser Sortiment",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Besuchen Sie unseren Onlineshop,<br /> um unser gesamtes Sortiment an Mode<br /> und Accessoires zu sehen.</p>",
            heroBtn: "Shop besuchen",
            shippingHeader: "Versand, Logistik und Belieferung",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Unsere Story",
            heroP: "KLÄDE ist das Resultat der Zusammenarbeit von zwei Skibegeisterten – Didier Rochereau und Berndt Långström –, die sich vor zehn Jahren zusammengetan haben, um ihre Vision einer neuen Modemarke Realität werden zu lassen. Ihr Ziel war einfach: langlebige, hochwertige Kleidung und Accessoires auf einen breiteren, vielschichtigeren Markt zu bringen.",
            section1P1: "In den Anfängen von KLÄDE entwarf Didier unverkennbare, trendige Artikel, während Berndt in der bescheidenen Umgebung seiner Werkstatt sein fundiertes Wissen rund um Materialien und Stoffe einsetzte, um Didiers Designs entstehen zu lassen. Gemeinsam schufen sie stilvolle und dennoch robuste Kleidung, die nicht wie Fast Fashion der Wegwerfgesellschaft zum Opfer fallen würde.",
            section1P2: "Die ersten Produktlinien von KLÄDE, eine Kombination aus Komfort und Stil, waren auffällig genug, um das Interesse von Models und Sportlern zu wecken. Dadurch wurden auch die Medien aufmerksam, und schon bald vergrößerte sich die Kundenbasis, wodurch sich die Marke zu dem großen Player entwickeln konnte, der sie heute ist.",
            section2Header: "„Eine Kombination aus Komfort und Stil …“",
            section2P1: "Wir sind nun von unserem Produktions- und Bürostandort in Herlev, Dänemark, aus tätig und betreiben einen Flagship-Store in Kopenhagen sowie weitere Shops in allen skandinavischen Hauptstädten.",
            section2P2: "Doch dies ist erst der Beginn unserer Reise: ",
            section2P3: "In den nächsten Jahren werden wir unser Sortiment weiter ausbauen und neue Bereiche der Forschung und Entwicklung integrieren, um unsere Mission – stilvolle und praktische Kleidung für anspruchsvolle Kunden in aller Welt herzustellen – weiter zu verfolgen."
          },
          "/our-campaigns": {
            "heroContent": "<h4 class='text-white font-bebas text-5xl'>KLÄDE IN DEN USA </h4><p class='font-courierNew font-bold text-base text-white'>Auf die Stecknadeln in der Karte klicken, um die Destinationen zu sehen, die wir bereits besucht haben.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Seien Sie dabei, wenn wir unsere neue Winterkollektion in die USA bringen!",
            section1P2: " Die Markenbotschafter von Kläde, Ferdie Zorrilla und Holly Beatrice-Wells, reisen in einige der beliebtesten Skigebiete der USA, um unsere neue Kollektion vorzustellen. Überall, wo sie für kurze Zeit haltmachen, werden wir unsere neuesten Artikel in den Skimodegeschäften vor Ort präsentieren. ",
            section1P3: " Nach Start der Kampagne in Aspen, Colorado, haben sich unsere Markenbotschafter ins nahegelegene Beaver Creek und nach Steamboat aufgemacht, um später noch Utah, Wyoming und Kalifornien zu besuchen. Erst vor Kurzem machten sie halt im Okemo Mountain Resort in Vermont. ",
            section1Heading1: "Nächster Stopp:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1: "Alle Updates finden Sie hier, oder folgen Sie unseren Markenbotschaftern in den sozialen Medien:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • vor 8 Stunden</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Was für ein Start unserer Tour! Wir freuen uns, Partner von Kläde zu sein, einer europäischen Marke, die ihr unbedingt auf dem Schirm haben solltet. Ich trage ihre Jacken TÄGLICH. Der Hype ist nicht mehr aufzuhalten!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • vor 2 Stunden</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Tschüss, Aspen! Es war einfach toll! Ich kann es kaum erwarten, die Pisten in Maine unsicher zu machen! Sehen wir uns dort?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Folgen"
          },
          "/people": {
            heroHeader: "Unsere Mitarbeiter",
            heroP: "Für mehr Informationen auf die Bilder der Personen klicken",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Verkaufs-und Marketingleiter",
                  "Tiiu stammt aus Helsinki und war Mitglied des Königlich Schwedischen Balletts, bevor er eine Umschulung zum Verkäufer absolvierte. Sie machte Urlaub in Hemsedal, wo Didier ihr Skilehrer war. Anschließend arbeitete Tiiu in Växjö, um bei der Verbesserung des Online-Shops von Outnorth AB zu helfen, einem großen Einzelhändler für skandinavische Outdoor-Bekleidung und -Ausrüstung. Sie fand sich schnell zurecht und stieg nach einem Jahr zur Leiterin des E-Commerce-Geschäfts auf. Als Didier sie wegen der Möglichkeit kontaktierte, die Online-Präsenz eines neuen Modeunternehmens aufzubauen, war Tiiu bereit, wieder in einem kleineren Unternehmen zu arbeiten. Seitdem hat sie die Marketing- und Kommunikationsstrategie von KLÄDE neu erfunden und sich als geschickt darin erwiesen, Beziehungen zu Kunden und Unternehmen aufzubauen.",
                  "Tiius Kindheitsheld war David Attenborough und auch heute noch ist sie eine begeisterte Amateur-Naturforscherin mit einem besonderen Interesse an Vögeln.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Produktionsleiter",
                  "Vijay wurde als Sohn indischer Eltern in Kopenhagen geboren und studierte Mathematik an der Universität Süddänemark. Nach seinem Abschluss arbeitete er beim dänischen Handelsmarken-Bekleidungshersteller United Textile Group (UTG), der sich auf umweltfreundliche und nachhaltige Produktionsmethoden spezialisiert hat. Als er sah, dass ein progressiver Bekleidungsdesigner jemanden mit Fertigungserfahrung suchte, nutzte Vijay die Chance, mehr Autonomie zu erlangen und eine kreativere Rolle in einem aufstrebenden Unternehmen zu übernehmen. Als Vijay zu KLÄDE kam, arbeiteten nur 10 Personen für KLÄDE, aber seitdem ist er einer unserer wichtigsten Befürworter von Wachstum und Expansion und stets auf der Suche nach übersehenen oder verborgenen Möglichkeiten.",
                  "In seiner Freizeit beschäftigt sich Vijay mit Puzzles und Brettspielen.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Leiter Forschung und Entwicklung und Mitbegründer",
                  "Ursprünglich aus Malmö, Schweden, zog Berndt in den Norden an die Universität Luleå, um Materialwissenschaften zu studieren. Dort kam ihm die Idee, Materialien zu entwickeln, die den Träger warm und trocken halten und gleichzeitig leicht und flexibel genug sind, um anstrengende Outdoor-Aktivitäten zu ermöglichen. Auf einem Skiausflug nach Hemsedal in Norwegen lernte er Didier kennen – und KLÄDE wurde geboren. Berndts Postgraduiertenprojekt führte zur Entwicklung eines flexiblen, leichten, atmungsaktiven, aber wasserdichten Stoffes. Mit der neuen Winterkollektion von KLÄDE hat Berndt dieses Wissen genutzt und praktische, stilvolle Kleidung für eine Reihe von Aktivitäten bei kaltem Wetter entworfen.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Finanzchef",
                  "Luke studierte Finanzen im Rahmen seines Business Administration BBA an der BI Norwegian Business School, bevor er sich eine Auszeit nahm, um seiner Leidenschaft für das Reisen nachzugehen. Während seines Aufenthalts in Singapur fand er eine Stelle als Junior Business Analyst bei Deloitte und blieb dort drei Jahre. Luke vermisste seine Familie und sein Zuhause in Südkorea und kehrte dorthin mit der Erwartung zurück, für ein anderes multinationales Beratungsunternehmen zu arbeiten, bis ein revolutionäres neues Bekleidungsunternehmen in Dänemark sein Interesse weckte. Luke genießt die Chance, in einem kleinen Team zu arbeiten, und sein global denkender, aber dennoch detailorientierter Ansatz war für das Wachstum von KLÄDE von unschätzbarem Wert.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "E-Commerce-Verkaufsmanager",
                  "Alma stammt aus Aalborg, Dänemark und war im Modeverkauf in Kopenhagen tätig. Sie absolvierte eine Umschulung, erwarb einen Master of Science in Betriebswirtschaft, Informationssystemen und E-Business an der Copenhagen Business School und nahm vor ein paar Jahren eine Stelle bei KLÄDE an, wo sie für deren E-Commerce-Kanal verantwortlich ist.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Präsident, Leiter Design und Mitbegründer",
                  "Didier wuchs bei seinem französischen Vater und seiner marokkanischen Mutter in Chamonix in den Alpen auf, wo er bald nach dem Laufen das Skifahren lernte. Er erwarb ein Diplom in Modedesign an der renommierten Modeschule ESMOD in Paris, Frankreich, und lernte seinen zukünftigen Geschäftspartner kennen, als er als Skilehrer in Norwegen arbeitete. Didier lebt in Kopenhagen und geht immer noch gerne auf die Pisten in den Alpen.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Kontakt",
            formP1: "Formular unten ausfüllen.",
            formEmail: "Ihre E-Mail-Adresse",
            formSelect: "Option wählen",
            formOption1: "Option 1",
            formOption2: "Option 2",
            formOption3: "Option 3",
            formSubject: "Betreff",
            formTextarea: "Kommentare",
            formSubmit: "Senden"
          },
        },
      },
      //IT done
      {
        language: "it",
        languageName: "Italiano",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "La nuova gamma" },
            { item: "La nostra gamma" },
            { item: "La nostra storia" },
            { item: "Le nostre campagne" },
            { item: "Il team" },
            { item: "Contatti" }
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Inserisci password: "
          },
        },
        route: {
          "/": {
            banner: "Visualizza in anteprima la nostra nuova gamma",
            leftHeader1: "Comfort.",
            leftHeader2: "Eleganza.",
            leftHeader3: "Innovazione.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Stile scandinavo.",
            rightHeader5: "Ovunque."
          },
          "/new-range": {
            banner: "Prossimamente",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Comfort e versatilità<br class='md:hidden' /> per<br class='hidden md:block'/> le avventure in qualsiasi momento dell’anno</h4>",
            p1: "Cerca la nostra prossima gamma di abbigliamento invernale. La nostra nuova linea, che si posiziona nella fascia alta dell’abbigliamento invernale, è realizzata con i materiali più innovativi per assicurare comfort e flessibilità all’aperto.",
            p2: "Il nostro tessuto esterno impermeabile e traspirante offre la stessa versalità di una giacca shell, mentre il piumino di vera piuma della migliore qualità assicura il calore e il comfort di una giacca che offre il massimo isolamento.",
            p3: "Adatta a tutte le attività e professioni svolte all’aperto."
          },
          "/our-range": {
            heroHeader: "La nostra gamma",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Visita il nostro negozio online per<br /> scoprire tutta la nostra gamma di abbigliamento<br /> e accessori.</p>",
            heroBtn: "Visita il negozio",
            shippingHeader: "Spedizioni, logistica e forniture",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "La nostra storia",
            heroP: "KLÄDE è il frutto della collaborazione tra due amanti dello sci, Didier Rochereau e Berndt Långström, che dieci anni fa hanno unito le forze per dar vita alla vision comune di creare un nuovo brand di abbigliamento. Avevano un obiettivo semplice: mettere a disposizione di un mercato più ampio e più variegato capi e accessori durevoli e di ottima qualità.",
            section1P1: "All’inizio di KLÄDE, Didier progettava articoli peculiari e di tendenza mentre Berndt, nell’umile location del suo garage, metteva a frutto le sue ampie conoscenze su materiali e tessuti per trasformare la vision di Didier in realtà. Insieme hanno creato capi stilosi ma resistenti che avrebbero resistito alle abitudini consumistiche della fast fashion.",
            section1P2: "Le prime linee di prodotti KLÄDE, confortevoli e stilose, erano abbastanza straordinarie da attirare l’interesse locale di modelle e atleti. Presto hanno richiamato anche l’attenzione dei media e l’interesse di sempre più clienti, che hanno portato il brand a crescere fino a diventare la realtà di punta odierna.",
            section2Header: "“Un mix di comfort e stile…”",
            section2P1: "Ora operiamo dallo stabilimento produttivo e dagli uffici danesi di Herlev, ma anche dal nostro negozio monomarca nel centro di Copenaghen e da diversi altri punti vendita in tutte le altre capitali scandinave.",
            section2P2: "Ma questo è solo l’inizio del nostro percorso. ",
            section2P3: "Nei prossimi anni amplieremo la nostra portata fino a includere nuove aree della ricerca e sviluppo, proseguendo la mission di fornire capi di abbigliamento alla moda e pratici a consumatori esigenti in tutto il mondo."
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl'>KLÄDE NEGLI USA </h4><p class='font-courierNew font-bold text-base text-white'>Fai clic sui segnaposto sulla mappa per visualizzare le destinazioni che abbiamo visitato.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Seguici durante il nostro viaggio per far conoscere la nostra collezione invernale negli USA!",
            section1P2: " I brand ambassador Kläde Ferdie Zorrilla e Holly Beatrice-Wells stanno visitando alcune delle località sciistiche più famose delle Stati Uniti per promuovere la nostra nuova linea. A ogni tappa, ma solo per un periodo di tempo limitato, riforniremo i negozi di abbigliamento da sci del luogo con i nostri ultimi articoli. ",
            section1P3: " Dopo il lancio della campagna ad Aspen, nel Colorado, i nostri brand ambassador si sono spostati nelle vicine località di Beaver Creek e Steamboat, prima di partire alla volta dello Utah, del Wyoming e della California. Ultimamente sono stati avvistati nell’Okemo Mountain Resort, in Vermont. ",
            section1Heading1: "Prossima tappa:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1: "Leggi qui gli aggiornamenti o segui i nostri brand ambassador sui social media:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 ore fa</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Che maniera fantastica di iniziare questo tour! Contento di collaborare con Kläde, un brand europeo che vi consiglio di seguire. Indosso le loro giacche OGNI GIORNO. È iniziato ufficialmente l’hype!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 ore fa</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Arrivederci Aspen! È stato pazzesco!! Non vedo l’ora di lanciarmi sulle piste del Maine! Ci vediamo lì?<span class='text-green'>#KlädeStyle #neve #montagna# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Segui"
          },
          "/people": {
            heroHeader: "Il nostro team",
            heroP: "Fai clic su ciascuna foto per avere maggiori informazioni",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Direttore vendite e marketing",
                  "Originario di Helsinki, Tiiu è stato membro del Royal Swedish Ballet, prima di riqualificarsi come venditore. Andava in vacanza a Hemsedal, dove Didier era il suo maestro di sci. Tiiu ha continuato a lavorare a Växjö per contribuire a migliorare il negozio online di Outnorth AB, un grande rivenditore di abbigliamento e attrezzature scandinave per l'outdoor. Trovando rapidamente la sua strada, dopo un anno è diventata responsabile delle operazioni di e-commerce. Quando Didier l'ha contattata riguardo all'opportunità di costruire la presenza online di una nuova azienda di moda, Tiiu era pronta a lavorare ancora una volta in un'operazione più piccola. Da allora ha reinventato la strategia di marketing e comunicazione di KLÄDE e ha dimostrato di essere abile nel costruire relazioni con clienti e aziende.",
                  "L'eroe d'infanzia di Tiiu era David Attenborough e oggi è ancora un'appassionata naturalista dilettante con un interesse speciale per gli uccelli.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Manager di produzione",
                  "Nato a Copenaghen da genitori indiani, Vijay ha studiato matematica all'Università della Danimarca meridionale. Dopo la laurea, ha lavorato presso il produttore danese di abbigliamento a marchio del distributore United Textile Group (UTG), specializzato in metodi di produzione ecologici e sostenibili. Quando ha visto che un designer di abbigliamento progressista stava cercando qualcuno con esperienza nella produzione, Vijay ha colto l'occasione per acquisire maggiore autonomia e assumere un ruolo più creativo in un'azienda emergente. Quando Vijay è entrato a far parte di KLÄDE lavoravano solo 10 persone, ma da allora è stato uno dei nostri principali sostenitori della crescita e dell'espansione, sempre alla ricerca di opportunità trascurate o nascoste.",
                  "Nel tempo libero, Vijay si diverte con puzzle e giochi da tavolo.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Responsabile Ricerca e Sviluppo e Co-fondatore",
                  "Originario di Malmö, in Svezia, Berndt si è trasferito a nord, all'Università di Luleå, per studiare scienza dei materiali. Fu lì che ebbe l'idea di creare materiali che mantenessero chi li indossava caldo e asciutto pur essendo abbastanza leggeri e flessibili da consentire faticose attività all'aperto. Durante una vacanza sugli sci a Hemsedal, in Norvegia, ha incontrato Didier e così è nato KLÄDE. Il progetto post-laurea di Berndt ha portato alla creazione di un tessuto flessibile, leggero, traspirante, ma impermeabile. Con la nuova gamma invernale di KLÄDE, Berndt ha messo a frutto queste conoscenze progettando capi di abbigliamento pratici ed eleganti per una vasta gamma di attività nella stagione fredda.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Direttore finanziario",
                  "Luke ha studiato Finanza come parte del suo BBA in Amministrazione aziendale presso la BI Norwegian Business School, prima di prendersi del tempo per perseguire la sua passione per i viaggi. Mentre era a Singapore, trovò lavoro come Junior Business Analyst presso Deloitte e vi rimase per tre anni. Avendo perso la famiglia e la casa in Corea del Sud, Luke è tornato lì con l'aspettativa di lavorare per un'altra società di consulenza multinazionale, finché una nuova dirompente azienda di abbigliamento in Danimarca non ha catturato il suo interesse. Apprezzando la possibilità di lavorare come parte di un piccolo team, l'approccio globale ma attento ai dettagli di Luke è stato una risorsa inestimabile per la crescita di KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Responsabile vendite e-commerce",
                  "Originaria di Aalborg, in Danimarca, Alma lavorava nelle vendite di moda a Copenaghen. Si è riqualificata, ha conseguito un Master of Science in Amministrazione aziendale, Sistemi informativi ed E-business presso la Copenhagen Business School e un paio di anni fa ha accettato un lavoro presso KLÄDE, dove è responsabile del loro canale di e-commerce.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Presidente, Responsabile del Design e Co-Fondatore",
                  "Didier è cresciuto da padre francese e madre marocchina a Chamonix, nelle Alpi, dove ha imparato a sciare subito dopo aver imparato a camminare. Ha conseguito un diploma in Fashion Design presso la stimata scuola di moda ESMOD a Parigi, Francia, e ha incontrato il suo futuro socio in affari mentre lavorava come maestro di sci in Norvegia. Didier vive a Copenaghen e gli piace ancora andare sulle piste delle Alpi.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Contatti",
            formP1: "Compila il modulo di seguito.",
            formEmail: "E-mail",
            formSelect: "Seleziona l’opzione che preferisci",
            formOption1: "Opzione 1",
            formOption2: "Opzione 2",
            formOption3: "Opzione 3",
            formSubject: "Oggetto",
            formTextarea: "Commenti",
            formSubmit: "Invia"
          },
        },
      },
      //NL done
      {
        language: "nl",
        languageName: "Netherlands",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Nieuw assortiment" },
            { item: "Ons assortiment" },
            { item: "Ons verhaal" },
            { item: "Onze campagnes" },
            { item: "Mensen" },
            { item: "Contact" }
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Voer een wachtwoord in: "
          },
        },
        route: {
          "/": {
            banner: "Bekijk ons nieuwe assortiment",
            leftHeader1: "Comfort.",
            leftHeader2: "Elegantie.",
            leftHeader3: "Innovatie.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Scandinavische Stijl.",
            rightHeader5: "Overal."
          },
          "/new-range": {
            banner: "Binnenkort",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Veelzijdig comfort<br class='md:hidden' /> voor<br class='hidden md:block'/> avonturen gedurende het hele jaar</h4>",
            p1: "Houd onze winterkleding in de gaten. Deze nieuwe lijn in het topsegment van de winterkleding gebruikt de meest geavanceerde materialen om je outdoor draagcomfort en flexibiliteit te bieden.",
            p2: "Onze eigen waterdichte, ademende buitenstof heeft alle veelzijdigheid van een shelljacket, en de fijnste pure donsveren zorgen voor de warmte en het comfort van een volledig geïsoleerde jas.",
            p3: "Geschikt voor alle buitenactiviteiten en beroepen."
          },
          "/our-range": {
            heroHeader: "Ons assortiment",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Bezoek onze online winkel om<br /> ons volledige assortiment kleding<br /> en accessoires te bekijken.</p>",
            heroBtn: "Bezoek de winkel",
            shippingHeader: "Verzending, Logistiek & Voorraad",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Ons verhaal",
            heroP: "KLÄDE is het resultaat van een samenwerking tussen twee enthousiaste skiërs, Didier Rochereau en Berndt Långström, die tien jaar geleden hun krachten bundelden om hun visie van een nieuw kledingmerk te realiseren. Hun doel was simpel: duurzame, eersteklas kleding en accessoires op een bredere, meer diverse markt brengen.",
            section1P1: "In de beginperiode van KLÄDE ontwierp Didier opvallende, trendy items terwijl Berndt, in de bescheiden omgeving van zijn garage, zijn diepgaande kennis van materialen en stoffen gebruikte om Didiers ontwerpen te realiseren. Samen creëerden ze stijlvolle maar robuuste kleding die niet zou bezwijken onder de wegwerpcultuur van fast fashion.",
            section1P2: "Door comfort en stijl te combineren, waren de eerste productlijnen van KLÄDE opvallend genoeg om lokale steun van modellen en atleten aan te trekken. Media-aandacht en een groeiende consumentenbasis volgden al snel, waardoor het merk uitgroeide tot de grote speler die het nu is.",
            section2Header: "“Combineert comfort en stijl…”",
            section2P1: "Momenteel werken we vanuit een productie- en kantoorlocatie in Herlev, Denemarken, en vanuit onze flagshipstore in het centrum van Kopenhagen en verschillende andere winkels in alle Scandinavische hoofdsteden.",
            section2P2: "Maar dit is nog maar het begin van onze reis. ",
            section2P3: "De komende jaren zullen we onze reikwijdte uitbreiden naar nieuwe gebieden van onderzoek en ontwikkeling, ter bevordering van onze missie om stijlvolle, praktische kleding te leveren aan veeleisende consumenten over de hele wereld."
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE IN<br /> DE VS </h4><p class='font-courierNew font-bold text-base text-white'>Klik op de pins op de kaart om bestemmingen te bekijken die we hebben bezocht.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Volg ons terwijl we onze nieuwe winterkledinglijn naar de VS brengen!",
            section1P2: " De Kläde merkambassadeurs Ferdie Zorrilla en Holly Beatrice-Wells toeren langs een aantal van de populairste skigebieden in de Verenigde Staten om ons nieuwe assortiment te promoten. Bij elke bestemming brengen we gedurende een beperkte tijd onze nieuwste artikelen naar de plaatselijke skikledingwinkels. ",
            section1P3: " Onze merkambassadeurs lanceerden de campagne in Aspen, Colorado, en verschenen vervolgens in de nabijgelegen resorts Beaver Creek en Steamboat, voordat ze doorreisden naar Utah, Wyoming en Californië. Onlangs waren ze te zien in het Okemo Mountain Resort in Vermont. ",
            section1Heading1: "Volgende bestemming:",
            section1Heading2: "sugarloaf, Maine.",
            section2P1: "Kijk hier voor updates of volg onze merkambassadeurs op sociale media:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 uur geleden</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Wat een manier om onze tour te beginnen! Blij om samen te werken met Kläde, een Europees merk dat je niet mag missen. Ik draag hun jassen DAGELIJKS. De hype-trein heeft het station verlaten!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 uur geleden</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Tot ziens Aspen! Het was een wilde rit! Ik kan niet wachten om de pistes op te gaan in Maine! Zie ik je daar?<span class='text-green'>#KlädeStyle #sneeuw #bergen# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Volgen"
          },
          "/people": {
            heroHeader: "Onze mensen",
            heroP: "Klik op de afbeelding van elke persoon voor meer informatie",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Verkoop- en marketingdirecteur",
                  "Tiiu, geboren in Helsinki, was lid van het Koninklijk Zweeds Ballet, voordat ze zich omschoolde tot verkoper. Ze ging op vakantie naar Hemsedal, waar Didier haar skileraar was. Tiiu ging vervolgens in Växjö werken om de online winkel van Outnorth AB, een grote retailer van Scandinavische outdoorkleding en -uitrusting, te helpen verbeteren. Ze vond snel haar weg en klom na een jaar op om leiding te geven aan de e-commerce-operatie. Toen Didier contact met haar opnam over de mogelijkheid om de online aanwezigheid van een nieuw modebedrijf op te bouwen, was Tiiu klaar om opnieuw in een kleinere onderneming te werken. Sindsdien heeft ze de marketing- en communicatiestrategie van KLÄDE opnieuw uitgevonden en heeft ze bewezen bedreven te zijn in het opbouwen van relaties met klanten en bedrijven.",
                  "Tiiu's jeugdheld was David Attenborough en vandaag de dag is ze nog steeds een enthousiaste amateur-natuuronderzoeker met een bijzondere interesse in vogels.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Productie Manager",
                  "Vijay, geboren in Kopenhagen uit Indiase ouders, studeerde wiskunde aan de Universiteit van Zuid-Denemarken. Na zijn afstuderen werkte hij bij de Deense private label kledingfabrikant United Textile Group (UTG), gespecialiseerd in groene en duurzame productiemethoden. Toen hij zag dat een vooruitstrevende kledingontwerper iemand zocht met productie-ervaring, greep Vijay de kans om meer autonomie te verwerven en een creatievere rol op zich te nemen in een opkomend bedrijf. Er werkten slechts 10 mensen voor KLÄDE toen Vijay lid werd, maar sindsdien is hij een van onze belangrijkste pleitbezorgers voor groei en expansie, altijd op zoek naar over het hoofd geziene of verborgen kansen.",
                  "In zijn vrije tijd houdt Vijay van puzzels en bordspellen.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Hoofd R&D en mede-oprichter",
                  "Oorspronkelijk afkomstig uit Malmö, Zweden, verhuisde Berndt naar het noorden, naar de Universiteit van Luleå, om materiaalkunde te studeren. Daar kwam hij op het idee om materialen te creëren die de dragers warm en droog zouden houden en tegelijkertijd licht en flexibel genoeg zouden zijn om inspannende buitenactiviteiten mogelijk te maken. Tijdens een skireis naar Hemsedal, Noorwegen, ontmoette hij Didier – en KLÄDE was geboren. Het postdoctorale project van Berndt leidde tot de creatie van een flexibele, lichtgewicht, ademende, maar waterdichte stof. Met het nieuwe winterassortiment van KLÄDE heeft Berndt die kennis goed gebruikt door praktische, stijlvolle kleding te ontwerpen voor een reeks activiteiten bij koud weer.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Financieel directeur",
                  "Luke studeerde Financiën als onderdeel van zijn BBA Bedrijfskunde aan de BI Norwegian Business School, voordat hij wat tijd vrijmaakte om zijn passie voor reizen na te streven. In Singapore vond hij werk als Junior Business Analyst bij Deloitte en bleef daar drie jaar. Omdat hij familie en thuis in Zuid-Korea miste, keerde Luke daar terug in de verwachting voor een ander multinationaal adviesbureau te gaan werken, totdat een disruptief nieuw kledingbedrijf in Denemarken zijn interesse wekte. Luke geniet van de kans om als onderdeel van een klein team te werken en de mondiale, maar toch detailgerichte aanpak is van onschatbare waarde geweest voor de groei van KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Verkoopmanager e-commerce",
                  "Alma, geboren in Aalborg, Denemarken, werkte in de modeverkoop in Kopenhagen. Ze heeft zich omgeschoold, heeft een Master of Science in Business Administration, Informatiesystemen en E-business behaald aan de Copenhagen Business School en is een paar jaar geleden gaan werken bij KLÄDE, waar ze verantwoordelijk is voor hun e-commercekanaal.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Voorzitter, hoofd ontwerp en mede-oprichter",
                  "Didier werd opgevoed door zijn Franse vader en Marokkaanse moeder in Chamonix in de Alpen, waar hij kort nadat hij leerde lopen leerde skiën. Hij behaalde een diploma in modeontwerp aan de gewaardeerde modeschool ESMOD in Parijs, Frankrijk, en ontmoette zijn toekomstige zakenpartner terwijl hij als skileraar in Noorwegen werkte. Didier woont in Kopenhagen en gaat nog steeds graag naar de pistes in de Alpen.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Neem contact op",
            formP1: "Vul het onderstaande formulier in.",
            formEmail: "Jouw e-mail",
            formSelect: "Selecteer je optie",
            formOption1: "Optie 1",
            formOption2: "Optie 2",
            formOption3: "Optie 3",
            formSubject: "Onderwerp",
            formTextarea: "Opmerkingen",
            formSubmit: "Verzenden"
          },
        },
      },
      //JA done
      {
        language: "ja",
        languageName: "日本語",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "新商品" },
            { item: "商品ラインナップ" },
            { item: "私達のストーリー" },
            { item: "キャンペーン" },
            { item: "社員" },
            { item: "お問い合わせ" }
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "パスワードを入力："
          },
        },
        route: {
          "/": {
            banner: "新商品プレビュー",
            leftHeader1: "快適性",
            leftHeader2: "エレガンス",
            leftHeader3: "革新.",
            rightHeader1: "。",
            rightHeader2: "。",
            rightHeader3: "。",
            rightHeader4: "北欧スタイルを.",
            rightHeader5: "世界中に."
          },
          "/new-range": {
            banner: "近日公開",
            header:  "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>一年中冒険を楽しめる<br class='md:hidden' /><br class='hidden md:block'/>多彩な快適性</h4>",
              p1: "来冬のアパレル商品をご覧ください。ハイエンドの冬服では、最先端の素材を採用し、アウトドアでの快適性と柔軟さを提供します。",
              p2: "防水・通気性に優れた独自のアウター素材は、シェルジャケットとしての機能性を備えており、最高級のダウンフェザーは、完全保温ジャケットの暖かさと快適さを提供します。",
              p3: "すべてのアウトドア愛好家とプロフェッショナルに向けた製品です。"
          },
          "/our-range": {
            heroHeader: "商品ラインナップ",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>衣料品とアクセサリーの<br />商品ラインナップは、<br />オンラインストアでご覧いただけます。</p>",
            heroBtn: "ストアにアクセス",
            shippingHeader: "配送、ロジスティクス、サプライ",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" }
            ],
          },
          "/our-story": {
            heroHeader: "私達のストーリー",
            heroP: "KLÄDEは、10年前に新しい衣料品のブランドを作り、ビジョンを実現するために力を合わせた二人のスキー愛好家、ディディエ・ロシュローとベルント・ロングストロムの協力のもとで、設立されました。彼らの目標はシンプルでした。それは、耐久性があり、プレミアムな衣料品やアクセサリーを、より広範で多様な市場に提供することでした。",
            section1P1: "初期のKLÄDEでは、ディディエがトレンドに沿った独自のアイテムをデザインする一方、ベルントがガレージの穏やかな環境の中で、素材や織物に関する深い知識を活かし、ディディエのデザインを現実のものにしていました。彼らは力を合わせ、スタイリッシュな上に耐久性がある衣服を作り、ファストファッションの慣行である使い捨ての犠牲にならない製品の提供に努めました。",
            section1P2: "快適さとスタイルを両立させたKLÄDEの初期の商品ラインは、地元のモデルやスポーツ選手から支持を集めるのに十分なほど、際立ったものでした。メディアの注目と敏感な消費者への拡大が、今日の主要プレイヤーとしてのブランド確立に一役買いました。",
            section2Header: "「快適さとスタイルの両立」",
            section2P1: "現在、デンマークのヘアレウに製造拠点とオフィスを置き、コペンハーゲン中央の旗艦店をはじめ、スカンジナビア全域の首都で数店舗を展開しています。",
            section2P2: "しかし、これは私たちの旅の始まりにすぎません。 ",
            section2P3: "今後数年間のうちに、新たな研究開発分野にも事業領域を広げ、世界中の消費者の方々に、スタイリッシュで実用的な衣料品をお届けする使命を果たしていきたいと考えています。"
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl'>米国におけるKLÄDE</h4><p class='font-courierNew font-bold text-base text-white'>キャンペーンを実施した地点を表示するには、マップ上のピンをクリックしてください。</p>",
            mapItems: [
              { item: "タホ湖" },
              { item: "パークシティ・マウンテン" },
              { item: "アスペン、ビーバー・クリーク、スティームボート" },
              { item: "ジャクソンホール" },
              { item: "オケモ・マウンテン" }
            ],
            section1P1: "新しい冬物アパレルを米国に展開する私たちの取り組みにご参加ください！",
            section1P2: " KlädeブランドのアンバサダーFerdie ZorrillaとHolly Beatrice-Wellsは、米国で最も人気のあるスキーリゾート数か所を訪ね、当社の新商品のプロモーションを行っています。各訪問先では、期間限定で、地元のスキーウェア店で当社最新の商品購入いただけます。 ",
            section1P3: " コロラド州アスペンでキャンペーンを開始した私たちのブランドアンバサダーは、近隣のビーバークリークとスティームボートのリゾートに登場した後、ユタ、ワイオミング、カリフォルニアへと向かいました。最近、彼らはバーモント州のオケモマウンテンリゾートを訪ねています。 ",
            section1Heading1: "次の訪問先：",
            section1Heading2: "メイン州シュガーローフ。",
            section2P1: "ここで最新情報を確認するか、ソーシャルメディアで当社のブランドアンバサダーをフォローできます：",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8時間前</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>素晴らしいツアーを始めます！Klädeというヨーロッパのブランドと提携できることを嬉しく思います。毎日のように彼らのジャケットを着用しています。最高潮に盛り上がっています！<span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2時間前</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>アスペン、またね！素晴らしい旅になりました！メイン州の山々が待ちきれません！後ほどお会いしましょう！<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "フォロー"
          },
          "/people": {
            heroHeader: "当社のリーダー",
            heroP: "詳細については、各個人の画像をクリックしてください",
            peopleItems: [
              {
                name: "ティーウ・コッケネン",
                description: [
                  "セールス＆マーケティングディレクター",
                  "ヘルシンキ出身のティウは、販売員として再訓練される前はスウェーデン王立バレエ団のメンバーでした。彼女はヘムセダールで休暇を過ごし、そこでディディエがスキーのインストラクターを務めました。 Tiiu はベクショーで働き、スカンジナビアのアウトドア衣料品と用品の大手小売業者 Outnorth AB のオンライン ストアの改善を支援しました。彼女はすぐに頭角を現し、1 年後には電子商取引事業の責任者に就任しました。ディディエが新しいファッション会社のオンライン プレゼンスを構築する機会について彼女に連絡したとき、ティウは再び小規模な事業で働く準備ができていました。それ以来、彼女は KLÄDE のマーケティングおよびコミュニケーション戦略を再構築し、顧客や企業との関係構築に熟達していることを示しました。",
                  "ティウさんの子供時代のヒーローはデビッド・アッテンボローで、今でも彼女は熱心なアマチュア博物学者であり、鳥に特別な興味を持っています。"
                ]
              },
              {
                name: "ビージェイ・バス",
                description: [
                  "プロダクションマネージャー",
                  "コペンハーゲンでインド人の両親のもとに生まれたビジェイは、南デンマーク大学で数学を学びました。卒業後は、グリーンで持続可能な生産方法を専門とするデンマークのプライベートブランド衣料品メーカー United Textile Group (UTG) で働きました。進歩的な衣料品デザイナーが製造経験のある人材を求めていることを知ったビジェイは、より自主性を獲得し、新進気鋭の会社でよりクリエイティブな役割を担うチャンスを掴みました。 Vijay が入社した当時、KLÄDE で働いていた従業員はわずか 10 名でしたが、それ以来、彼は成長と拡大を目指す重要な提唱者の 1 人となり、見落とされている機会や隠れた機会を常に探し続けています。",
                  "余暇には、Vijay はパズルやボード ゲームを楽しんでいます。"
                ]
              },
              {
                name: "ベルント・ロングストロム",
                description: [
                  "研究開発責任者兼共同創設者",
                  "スウェーデンのマルメ出身のベルントは、材料科学を学ぶために北のルレオ大学に移りました。そこで彼は、着用者を暖かくドライに保ちながら、激しい野外活動を可能にする十分な軽さと柔軟性を備えた素材を作成するというアイデアを思いつきました。ノルウェーのヘムセダールへのスキー旅行中にディディエと出会い、KLÄDE が誕生しました。 Berndt の大学院プロジェクトは、柔軟で軽量、通気性がありながら防水性のある生地の作成につながりました。 KLÄDE の新しい冬物シリーズでは、ベルントはその知識を活かして、さまざまな寒冷地での活動に適した実用的でスタイリッシュな衣服をデザインしました。"
                ]
              },
              {
                name: "ルーク・ヨハ",
                description: [
                  "経理部長",
                  "Luke は、BI ノルウェー ビジネス スクールで経営管理 BBA の一環として金融を学び、その後、旅行への情熱を追求するために時間を取りました。シンガポール滞在中にデロイトでジュニア ビジネス アナリストとしての仕事を見つけ、そこに 3 年間滞在しました。韓国で家族も家も失ったルークは、別の多国籍コンサルティング会社で働くことを期待して韓国に戻ったが、デンマークの革新的な新興衣料品会社が彼の興味を惹いた。少人数のチームの一員として働く機会を楽しみながら、ルークの世界的な視野を持ちながら細部にまでこだわったアプローチは、KLÄDE の成長にとって貴重な資産となっています。"
                ]
              },
              {
                name: "アルマ・アンダーセン",
                description: [
                  "eコマースセールスマネージャー",
                  "デンマークのオールボー出身のアルマは、コペンハーゲンでファッション販売の仕事をしていました。彼女は再訓練を受け、コペンハーゲン ビジネス スクールで経営管理、情報システム、電子ビジネスの修士号を取得し、数年前に KLÄDE に就職し、そこで電子商取引チャネルの責任者を務めています。"
                ]
              },
              {
                name: "ディディエ・ロシュロー",
                description: [
                  "社長、デザイン責任者、共同創設者",
                  "ディディエはフランス人の父親とモロッコ人の母親によってアルプスのシャモニーで育てられ、歩くことを覚えてからすぐにスキーを学びました。彼は、フランスのパリにある評判の高いファッション学校 ESMOD でファッション デザインのディプロマを取得し、ノルウェーでスキー インストラクターとして働いているときに、やがてビジネス パートナーとなる人と出会いました。ディディエはコペンハーゲンに住んでいますが、今でもアルプスの斜面を登るのを楽しんでいます。"
                ]
              }
            ]
          },
          "/contact": {
            formHeader: "お問い合わせ",
            formP1: "以下のフォームに入力してください。",
            formEmail: "メールアドレス",
            formSelect: "選択肢の選択",
            formOption1: "選択肢1",
            formOption2: "選択肢2",
            formOption3: "選択肢3",
            formSubject: "件名",
            formTextarea: "コメント",
            formSubmit: "送信"
          }
        },
      },
      //Ko done
      {
        language: "ko",
        languageName: "Korean",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "새로운 범위" },
            { item: "우리의 범위" },
            { item: "우리의 이야기" },
            { item: "우리의 캠페인" },
            { item: "사람들" },
            { item: "연락하다" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Enter password: ",
          },
        },
        route: {
          "/": {
            banner: "Preview Our New Range",
            leftHeader1: "편안함.",
            leftHeader2: "우아함.",
            leftHeader3: "혁신.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "스칸디나비안 스타일.",
            rightHeader5: "어디서나."
          },
          "/new-range": {
            banner: "출시 예정",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>언제든 모험을 즐길 수 있는 <br class='md:hidden' /> <br class='hidden md:block'/> 다재다능한 편안함</h4>",
              p1: "출시 예정 겨울 의류 상품을 확인하세요. 고급 겨울 의류인 신상품 라인은 가장 진화된 직물을 사용하여 야외에서 편안함과 유연성을 유지합니다.",
              p2: "당사만의 방수 통기성 있는 겉감은 쉘 재킷의 다목적 기능을 모두 갖췄으며 가장 최고급 순수 솜깃털이 완벽하게 단열 처리된 재킷과 같은 따뜻함과 편안함을 제공합니다.",
              p3: "취미 생활 및 전문 야외 활동가 모두에게 좋습니다.",
          },
          "/our-range": {
            heroHeader: "우리의 범위",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>온라인 상점을 방문하셔서<br /> 의류 및 액세서리 라인 전체<br /> 를 확인하세요.</p>",
            heroBtn: "매장 방문",
            shippingHeader: "Shipping, Logistics & Supplies",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
           heroHeader: "우리의 이야기",
      heroP: "KLÄDE는 스키 애호가 Didier Rochereau와 Berndt Långström의 합작입니다. 이들은 10년 전 새로운 의류 브랜드 비전을 가지고 회사를 차렸습니다. 이 둘의 목표는 간단합니다. 튼튼한 프리미엄 의류와 액세서리를 더 넓고 다양한 시장에 내놓는 것입니다.",
      section1P1: "KLÄDE 창립 초창기에 Didier는 독특하며 트렌디한 상품을 디자인했으며 Berndt는 본인의 평범한 차고에서 재료 및 섬유에 관한 풍부한 지식을 활용하여 Didier의 디자인을 현실로 만들었습니다. 이 둘은 패스트 패션과 달리 몇 번 입고 버리지 않을 스타일리시하면서도 튼튼한 의류를 함께 제작했습니다.",
      section1P2: "편안함과 스타일을 갖춘 KLÄDE의 초기 상품 라인은 현지 모델과 운동 선수들의 지지를 받을 정도로 히트를 쳤습니다. 미디어가 주목하고 곧이어 소비자 층이 확장되면서 오늘날의 주요 패션 회사 브랜드가 될 수 있었습니다.",
      section2Header: "\"편안함과 스타일을 겸비한...\"",
      section2P1: "우리는 이제 덴마크 허르레브에 위치한 제조 공장 및 사무실뿐 아니라 코펜하겐 중심에 있는 대표 매장과 스칸디나비아 수도 전역에 있는 몇몇 다른 매장들도 운영하고 있습니다.",
      section2P2: "하지만 이것은 우리 여정의 시작일 뿐입니다. ",
      section2P3: "앞으로 우리는 새로운 연구 및 개발 영역을 포함할 수 있게 범위를 넓히면서 전 세계 안목 있는 소비자에게 스타일리시하고 실용적인 의류를 제공하겠다는 회사의 사명을 실천해 나갈 것입니다."
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-7xl'>미국의<br /> KLÄDE </h4><p class='font-courierNew font-bold text-base text-white'>우리가 방문했던 장소를 보려면 지도 핀을 클릭하세요.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" }
            ],
            section1P1: "새로운 겨울 의류 라인을 가지고 미국에 갑니다. 우리와 함께하세요!",
            section1P2: " Kläde 브랜드 홍보대사 Ferdie Zorrilla와 Holly Beatrice-Wells는 신상품을 홍보하기 위해 미국에서 가장 유명한 스키 리조트 몇 곳을 돌아 볼 예정입니다. 투어가 진행되는 동안 각 지역의 스키 의류 매점에서 Kläde의 최신 상품을 한정 기간 판매할 예정입니다. ",
            section1P3: " 브랜드 홍보대사들은 또한 콜로라도 아스펜에서 캠페인을 런칭한 후 비버 크릭과 스팀보트 리조트 근처에 들르며 유타, 와이오밍, 캘리포니아로 향했습니다. 가장 최근에 홍보대사들은 버몬트 오케모 마운틴 리조트에 들렀습니다. ",
            section1Heading1: "Next stop:",
            section1Heading2: "sugarloaf, Maine.",
            section2P1: "여기에서 최신 소식을 확인하거나 소셜미디어에서 브랜드 홍보대사를 팔로우하세요.",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8시간 전</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>멋지게 투어를 시작합니다! 놓치면 후회 할 유럽 브랜드, Kläde와 파트너가 되어서 행복합니다. 정말 매일 그 재킷을 입고 있어요. 과장 광고가 아니었네요!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2시간 전</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>아스펜 안녕! 신나는 여행이었어!! 얼른 메인에서 스키를 타고 싶네! 거기에서 만날래?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Follow",
          },
          "/people": {
            heroHeader: "우리 사람들",
            heroP: "자세한 내용을 보려면 각 인물 사진을 클릭하세요",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "영업 및 마케팅 이사",
                  "헬싱키 출신인 Tiiu는 영업사원으로 재교육을 받기 전에 스웨덴 왕립 발레단의 멤버였습니다. 그녀는 Didier가 스키 강사로 있던 Hemsedal에서 휴가를 보냈습니다. Tiiu는 계속해서 Växjö에서 일하며 스칸디나비아 아웃도어 의류 및 장비의 대규모 소매업체인 Outnorth AB의 온라인 매장 개선을 도왔습니다. 빠르게 자리를 잡은 그녀는 1년 만에 전자상거래 사업을 이끌게 되었습니다. Didier가 새로운 패션 회사의 온라인 입지를 구축할 수 있는 기회에 대해 그녀에게 연락했을 때 Tiiu는 다시 한번 소규모 사업장에서 일할 준비가 되어 있었습니다. 이후 그녀는 KLADE의 마케팅 및 커뮤니케이션 전략을 재창조했으며 고객 및 기업과의 관계 구축에 능숙함을 보여주었습니다.",
                  "Tiiu의 어린 시절 영웅은 David Attenborough였으며 오늘날 그녀는 여전히 새에 특별한 관심을 갖고 있는 예리한 아마추어 박물학자입니다."
                ]
              },
              {
                name: "Vijay Basu",
                description: [
                  "생산 관리자",
                  "코펜하겐에서 인도인 부모 사이에서 태어난 Vijay는 남부 덴마크 대학교에서 수학을 전공했습니다. 졸업 후 그는 친환경적이고 지속 가능한 생산 방법을 전문으로 하는 덴마크 자체 상표 의류 제조업체 United Textile Group(UTG)에서 근무했습니다. 진보적인 의류 디자이너가 제조 경험이 있는 사람을 찾고 있는 것을 보고 Vijay는 유망 회사에서 더 많은 자율성을 확보하고 더 창의적인 역할을 맡을 기회를 잡았습니다. Vijay가 합류했을 당시 KLäDE에는 10명만이 근무하고 있었지만, 그 이후로 Vijay는 항상 간과되거나 숨겨진 기회를 찾아 성장과 확장을 추구하는 핵심 옹호자 중 한 사람이 되었습니다.",
                  "여가 시간에는 Vijay는 퍼즐과 보드 게임을 즐깁니다."
                ]
              },
              {
                name: "Berndt Långström",
                description: [
                  "R&D 책임자 및 공동 창립자",
                  "원래 스웨덴 말뫼 출신인 Berndt는 재료 과학을 공부하기 위해 북쪽의 Luleå 대학교로 이사했습니다. 그곳에서 그는 착용자를 따뜻하고 건조하게 유지하면서도 격렬한 야외 활동을 할 수 있을 만큼 가볍고 유연할 수 있는 소재를 만드는 아이디어를 떠올렸습니다. 그는 노르웨이 헴세달(Hemsedal)로 스키 여행을 가던 중 디디에(Didier)를 만났고, 그로부터 KLäDE가 태어났습니다. Berndt의 대학원 프로젝트를 통해 유연하고 가벼우며 통기성이 있으면서도 방수가 되는 직물이 탄생했습니다. Berndt는 KLADE의 새로운 겨울 제품군을 통해 이러한 지식을 활용하여 다양한 추운 날씨에 어울리는 실용적이고 스타일리시한 의류를 디자인했습니다."
                ]
              },
              {
                name: "Luke Joh",
                description: [
                  "재무 이사",
                  "Luke는 BI 노르웨이 비즈니스 스쿨에서 경영학 BBA의 일환으로 금융을 공부한 후 여행에 대한 열정을 추구하기 위해 시간을 냈습니다. 싱가포르에 있는 동안 그는 딜로이트(Deloitte)에서 주니어 비즈니스 분석가로 일했고 그곳에서 3년 동안 근무했습니다. 한국에서 가족과 집을 잃은 루크는 또 다른 다국적 컨설팅 회사에서 일할 것이라는 기대를 가지고 한국으로 돌아왔고, 덴마크의 파괴적인 새로운 의류 회사가 그의 관심을 끌었습니다. 소규모 팀의 일원으로 일할 수 있는 기회를 누린 Luke의 글로벌 마인드와 세부적인 접근 방식은 KLADE의 성장에 귀중한 자산이 되었습니다."
                ]
              },
              {
                name: "Alma Andersen",
                description: [
                  "전자상거래 영업 관리자",
                  "덴마크 올보르그 출신인 Alma는 코펜하겐에서 패션 세일즈 분야에 종사했습니다. 그녀는 재교육을 받고 코펜하겐 경영대학원에서 경영학, 정보 시스템 및 전자 비즈니스 석사 학위를 취득했으며 몇 년 전 KLäDE에서 전자 상거래 채널을 담당하는 직장에 취직했습니다."
                ]
              },
              {
                name: "Didier Rochereau",
                description: [
                  "사장, 디자인 책임자 및 공동 창립자",
                  "디디에는 알프스의 샤모니에서 프랑스인 아버지와 모로코인 어머니에 의해 자랐으며, 그곳에서 걷는 법을 배운 후 곧 스키를 배웠습니다. 그는 프랑스 파리의 명문 패션학교 에스모드(ESMOD)에서 패션 디자인 학위를 취득했고, 노르웨이에서 스키 강사로 일하면서 장래의 사업 파트너를 만났다. 디디에는 코펜하겐에 살고 있으며 여전히 알프스의 슬로프를 타는 것을 즐깁니다."
                ]
              }
            ]
          },
          "/contact": {
            formHeader: "문의하기",
            formP1: "아래 양식을 작성하세요.",
            formEmail: "귀하의 이메일",
            formSelect: "옵션을 선택하세요",
            formOption1: "옵션 1",
            formOption2: "옵션 2",
            formOption3: "옵션 3",
            formSubject: "주제",
            formTextarea: "코멘트",
            formSubmit: "제출하다",
          },
        },
      },
      //PL done
      {
        language: "pl",
        languageName: "Polski",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
          ],
          navItems: [
            { item: "Nowy asortyment" },
            { item: "Nasz asortyment" },
            { item: "Nasza historia" },
            { item: "Nasze kampanie" },
            { item: "Ludzie" },
            { item: "Kontakt" }
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Podaj hasło: "
          },
        },
        route: {
          "/": {
            banner: "Zobacz nasz nowy asortyment",
            leftHeader1: "Komfort.",
            leftHeader2: "Elegancja.",
            leftHeader3: "Innowacje.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Styl skandynawski.",
            rightHeader5: "W każdym miejscu."
          },
          "/new-range": {
            banner: "Już wkrótce",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Wszechstronny komfort<br class='md:hidden' /> dla<br class='hidden md:block'/> całorocznych przygód</h4>",
            p1: "Niedługo zaprezentujemy naszą zimową kolekcję odzieży. Jest to odzież zimowa z górnej półki, która wykorzystuje najbardziej zaawansowane technologicznie materiały, aby zapewniać jej użytkownikom wygodę i swobodę ruchów na świeżym powietrzu.",
            p2: "Opracowana przez nas wodoodporna tkanina oferuje pełną wszechstronność kurtki typu shell, a najwyższej jakości puch zapewnia ciepło i komfort kurtki z pełną izolacją termiczną.",
            p3: "Nadaje się do wszystkich rodzajów aktywności na świeżym powietrzu i dla przedstawicieli wszystkich zawodów."
          },
          "/our-range": {
            heroHeader: "Nasz asortyment",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Odwiedź nasz sklep online<br />, aby zobaczyć cały nasz asortyment odzieży<br /> i akcesoriów.</p>",
            heroBtn: "Odwiedź sklep",
            shippingHeader: "Transport, logistyka i dostawy",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Nasza historia",
            heroP: "KLÄDE to wynik współpracy dwóch entuzjastów narciarstwa: Didiera Rochereau i Berndta Långströma, którzy dziesięć lat temu połączyli siły, aby zrealizować swoją wizję nowej marki odzieżowej. Mieli prosty cel: zaoferować trwałe ubrania i akcesoria klasy premium szerszej, bardziej zróżnicowanej klienteli.",
            section1P1: "Na początku działalności KLÄDE Didier projektował wyróżniające się, modne produkty, podczas gdy Berndt – w swoim skromnym garażu – wykorzystywał swoją dogłębną znajomość materiałów i tkanin, aby projekty Didiera mogły stawać się rzeczywistością. Razem tworzyli stylowe, ale solidne ubrania, które nie miały być szybko wyrzucane, jak to zazwyczaj bywa w przypadku konsumentów fast fashion.",
            section1P2: "Łącząc komfort i styl, wczesne linie produktowe marki KLÄDE robiły wystarczające wrażenie, aby przyciągać lokalne wsparcie ze strony modeli, modelek i sportowców. Marką szybko zainteresowały się media, a liczba jej klientów stale rosła, co pomogło jej się rozwinąć i stać się jednym z głównych graczy na rynku, którym jest obecnie.",
            section2Header: "„Łącząc komfort i styl...”",
            section2P1: "Obecnie posiadamy zakład produkcyjny i biura w Herlev w Danii, flagowy sklep w centrum Kopenhagi, a także kilka sklepów we wszystkich skandynawskich stolicach.",
            section2P2: "To jest jednak dopiero początek naszej podróży. ",
            section2P3: "W nadchodzących latach będziemy poszerzać obszar naszego działania o nowe dziedziny badań i rozwoju, realizując naszą misję oferowania stylowych, praktycznych ubrań wymagającym konsumentom na całym świecie."
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE W<br /> USA </h4><p class='font-courierNew font-bold text-base text-white'>Kliknij pinezki na mapie, aby zobaczyć miejsca, w których byliśmy.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Dołącz do nas podczas wprowadzania naszej nowej kolekcji odzieży zimowej na rynek USA!",
            section1P2: " Ferdie Zorrilla i Holly Beatrice-Wells – ambasadorzy marki Kläde – odwiedzają niektóre z najbardziej popularnych ośrodków narciarskich w USA, aby promować nasze nowe produkty. W każdym z tych miejsc tylko przez określony czas będziemy sprzedawać nasze najnowsze ubrania w lokalnych sklepach narciarskich. ",
            section1P3: " Po rozpoczęciu naszej kampanii w Aspen w Kolorado ambasadorzy naszej marki odwiedzili pobliskie ośrodki Beaver Creek i Steamboat, a następnie udali się do Utah, Wyoming i Kalifornii. Niedawno pojawili się w Okemo Mountain Resort w Vermont. ",
            section1Heading1: "Następny postój:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1: "Sprawdź tutaj, czy są jakieś nowe wiadomości lub obserwuj ambasadorów naszej marki w mediach społecznościowych:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 godzin temu</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Co za sposób na rozpoczęcie naszego objazdu! Z przyjemnością współpracujemy z Kläde – europejską marką, którą wprost trzeba się zainteresować. Nosimy ich kurtki CODZIENNIE. Pociąg szumu medialnego odjechał ze stacji!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 godziny temu</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Żegnaj, Aspen! To była dzika jazda! Nie mogę się już doczekać stoków w Maine! Do zobaczenia tam?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Obserwuj"
          },
          "/people": {
            heroHeader: "Nasi ludzie",
            heroP: "Aby uzyskać więcej informacji o danej osobie, kliknij jej wizerunek.",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Dyrektor Sprzedaży i Marketingu",
                  "Pochodzący z Helsinek Tiiu był członkiem Królewskiego Baletu Szwedzkiego, zanim przekwalifikował się na sprzedawcę. Wakacje spędzała w Hemsedal, gdzie Didier był jej instruktorem narciarstwa. Tiiu kontynuował pracę w Växjö, gdzie pomagał ulepszyć sklep internetowy Outnorth AB, dużego sprzedawcy detalicznego skandynawskiej odzieży i sprzętu outdoorowego. Szybko stanęła na nogi i po roku została dyrektorem działu e-commerce. Kiedy Didier skontaktował się z nią w sprawie możliwości budowania obecności w Internecie nowej firmy modowej, Tiiu była gotowa ponownie pracować w mniejszej firmie. Od tego czasu na nowo odkryła strategię marketingową i komunikacyjną KLĘDE i udowodniła, że ​​jest biegła w budowaniu relacji z klientami i firmami.",
                  "Bohaterem Tiiu z dzieciństwa był David Attenborough, a dziś nadal jest zapaloną przyrodniczką-amatorką, szczególnie interesującą się ptakami.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Kierownik produkcji",
                  "Urodzony w Kopenhadze w rodzinie indyjskiej, Vijay studiował matematykę na Uniwersytecie Południowej Danii. Po ukończeniu studiów pracował w duńskim producencie odzieży pod marką własną United Textile Group (UTG), która specjalizuje się w ekologicznych i zrównoważonych metodach produkcji. Kiedy zobaczył, że postępowy projektant odzieży szuka kogoś z doświadczeniem w produkcji, Vijay wykorzystał szansę na zdobycie większej autonomii i przyjęcie bardziej kreatywnej roli w dobrze rozwijającej się firmie. Kiedy Vijay dołączył do KLĘDE, pracowało w nim tylko 10 osób, ale od tego czasu stał się on jednym z naszych kluczowych zwolenników wzrostu i ekspansji, zawsze poszukującym przeoczonych lub ukrytych możliwości.",
                  "W wolnym czasie Vijay lubi układać puzzle i gry planszowe.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Szef działu badań i rozwoju oraz współzałożyciel",
                  "Pochodzący z Malmö w Szwecji Berndt przeniósł się na północ, na Uniwersytet w Luleå, aby studiować inżynierię materiałową. To właśnie tam wpadł na pomysł stworzenia materiałów, które zapewnią użytkownikom ciepło i suchość, a jednocześnie będą lekkie i wystarczająco elastyczne, aby umożliwić forsowną aktywność na świeżym powietrzu. Podczas wyjazdu na narty do Hemsedal w Norwegii poznał Didiera i tak narodził się KLĘDE. Podyplomowy projekt Berndta doprowadził do stworzenia elastycznego, lekkiego, oddychającego, a jednocześnie wodoodpornego materiału. Dzięki nowej ofercie zimowej KLĘDE Berndt dobrze wykorzystał tę wiedzę, projektując praktyczną, stylową odzież do szeregu zajęć w zimne dni.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Dyrektor finansowy",
                  "Luke studiował finanse w ramach BBA Business Administration w BI Norweskiej Szkole Biznesu, po czym poświęcił trochę czasu na rozwijanie swojej pasji, jaką są podróże. Podczas pobytu w Singapurze znalazł pracę jako młodszy analityk biznesowy w Deloitte i przebywał tam przez trzy lata. Tęskniąc za rodziną i domem w Korei Południowej, Luke wrócił tam z nadzieją, że będzie pracował dla innej międzynarodowej firmy konsultingowej, dopóki jego zainteresowanie nie wzbudziła nowa, przełomowa firma odzieżowa w Danii. Ciesząc się możliwością pracy w małym zespole, globalne podejście Luke'a, a jednocześnie skupiające się na szczegółach, było nieocenionym atutem dla rozwoju KLĘDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Menedżer sprzedaży e-commerce",
                  "Pochodząca z Aalborga w Danii Alma zajmowała się sprzedażą odzieży w Kopenhadze. Przekwalifikowała się, uzyskała tytuł magistra w dziedzinie administracji biznesowej, systemów informatycznych i e-biznesu w Kopenhaskiej Szkole Biznesu, a kilka lat temu podjęła pracę w KLĘDE, gdzie jest odpowiedzialna za kanał e-commerce.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Prezes, szef działu projektowania i współzałożyciel",
                  "Didier był wychowywany przez swojego francuskiego ojca i marokańską matkę w Chamonix w Alpach, gdzie nauczył się jeździć na nartach wkrótce po tym, jak nauczył się chodzić. Uzyskał dyplom z projektowania mody w cenionej szkole mody ESMOD w Paryżu we Francji, a swojego przyszłego partnera biznesowego poznał pracując jako instruktor narciarstwa w Norwegii. Didier mieszka w Kopenhadze i nadal lubi jeździć na stokach w Alpach.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Skontaktuj się z nami",
            formP1: "Wypełnij poniższy formularz.",
            formEmail: "Twój adres e-mail",
            formSelect: "Wybierz swoją opcję",
            formOption1: "Opcja 1",
            formOption2: "Opcja 2",
            formOption3: "Opcja 3",
            formSubject: "Temat",
            formTextarea: "Uwagi",
            formSubmit: "Prześlij"
          },
        },
      },
      //PT done
      {
        language: "pt",
        languageName: "Português",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Nova gama" },
            { item: "A nossa gama" },
            { item: "A nossa história" },
            { item: "As nossas campanhas" },
            { item: "Pessoas" },
            { item: "Contacto" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Introduza a palavra-passe: ",
          },
        },
        route: {
          "/": {
            banner: "Pré-visualizar a nova gama",
            leftHeader1: "Conforto.",
            leftHeader2: "Elegância.",
            leftHeader3: "Inovação.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Estilo escandinavo.",
            rightHeader5: "Em qualquer lugar.",
          },
          "/new-range": {
            banner: "Brevemente",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Conforto versátil<br class='md:hidden' /> for<br class='hidden md:block'/> aventuras todo o ano</h4>",
            p1: "Fique a par da nossa próxima gama de roupa de inverno. Topo da gama do vestuário de inverno, a nossa nova linha utiliza os materiais mais avançados para manter quem a usa confortável e ágil ao ar livre.",
            p2: "O nosso tecido externo, impermeável e respirável, de fabrico próprio, tem toda a versatilidade de um casaco de exterior, enquanto a penugem pura da mais elevada qualidade proporciona o calor e o conforto de um casaco com total isolamento.",
            p3: "Adequado para todas as atividades e profissões ao ar livre.",
          },
          "/our-range": {
            heroHeader: "A nossa gama",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>Visite a nossa loja online para<br /> veja a gama completa de vestuário<br /> e acessórios.</p>",
            heroBtn: "Visitar loja",
            shippingHeader: "Envio, Logística e Produtos",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "A nossa história",
            heroP:
              "KLÄDE é o resultado de uma colaboração entre dois entusiastas do esqui, Didier Rochereau e Berndt Långström, que uniram forças, há dez anos, para concretizar a sua visão de uma nova marca de roupa. O objetivo era simples: levar roupa e acessórios duradouros e de qualidade superior a um mercado mais vasto e diversificado.",
            section1P1:
              "Nos inícios da KLÄDE, Didier desenhava produtos distintos e atuais, enquanto Berndt, no ambiente modesto da sua garagem, utilizava o seu profundo conhecimento sobre materiais e tecidos para tornar realidade os designs de Didier. Juntos, criaram vestuário elegante, mas robusto, que não fosse vítima dos hábitos descartáveis da moda rápida.",
            section1P2:
              "Combinando conforto e estilo, as primeiras linhas de produtos da KLÄDE foram suficientemente impressionantes para atrair o apoio local de modelos e atletas. A atenção dos meios de comunicação social e uma base de consumidores em expansão não tardaram em surgir, ajudando a transformar a marca no que é hoje.",
            section2Header: "“Combinando conforto e estilo...”",
            section2P1:
              "Atualmente, operamos a partir de um local de produção e escritórios em Herlev, na Dinamarca, bem como da nossa loja principal no centro de Copenhaga e de várias outras lojas em todas as capitais escandinavas.",
            section2P2: "Mas isto é apenas o início da viagem. ",
            section2P3:
              "Nos próximos anos, vamos alargar o nosso âmbito para incluir novas áreas de investigação e desenvolvimento, prosseguindo a missão de fornecer roupa elegante e prática a consumidores exigentes por todo o mundo.",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-5xl'>KLÄDE NOS<br /> EUA </h4><p class='font-courierNew font-bold text-base text-white'>Clique nos pinos de localização para ver os destinos que visitámos.</p>",
            mapItems: [
              { item: "Lago Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1:
              "Junte-se a nós e à nova gama de roupa de inverno da Kläde para os EUA!",
            section1P2:
              " Ferdie Zorrilla e Holly Beatrice-Wells, os embaixadores da Kläde, estão a percorrer alguns dos mais populares resorts de esqui dos Estados Unidos para promover a nova gama da marca. Em cada paragem, e durante um período limitado, vamos ter os produtos mais recentes em lojas locais de roupa de esqui. ",
            section1P3:
              " Lançando a campanha em Aspen, em Colorado, os nossos embaixadores da marca passaram pelos resorts vizinhos de Beaver Creek e Steamboat, antes de se dirigirem para o Utah, Wyoming e Califórnia. Mais recentemente, estiveram no Okemo Mountain Resort, em Vermont. ",
            section1Heading1: "Próxima paragem:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1:
              "Consulte aqui as atualizações ou siga os embaixadores da marca nas redes sociais:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • há 8 horas</p>",
            section2LeftP4:
              "<p class='font-helvetica mt-3 cardLeftText'>Que maneira de começar a nossa viagem! Estamos felizes com a parceria com a Kläde, uma marca europeia que deve estar no radar. Usamos os casacos DIARIAMENTE. O comboio do hype já saiu da estação!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • há 2 horas</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>Até à vista, Aspen! Foi uma viagem louca! Mal posso esperar para ir para as pistas em Maine! Vemo-nos lá?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Seguir",
          },
          "/people": {
            heroHeader: "As Nossas Pessoas",
            heroP: "Clique na imagem de cada pessoa para obter mais informações",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Diretor de Vendas e Marketing",
                  "Natural de Helsinque, Tiiu foi membro do Royal Swedish Ballet, antes de se reciclar como vendedor. Ela passou férias em Hemsedal, onde Didier foi seu instrutor de esqui. Tiiu passou a trabalhar em Växjö para ajudar a melhorar a loja online da Outnorth AB, um grande varejista de roupas e equipamentos escandinavos para atividades ao ar livre. Recuperando-se rapidamente, ela passou a liderar a operação de comércio eletrônico após um ano. Quando Didier a contatou sobre a oportunidade de construir a presença online de uma nova empresa de moda, Tiiu estava pronta para trabalhar novamente em uma operação menor. Desde então, ela reinventou a estratégia de marketing e comunicação da KLÄDE e demonstrou ser adepta da construção de relacionamentos com clientes e empresas.",
                  "O herói de infância de Tiiu foi David Attenborough e hoje ela ainda é uma naturalista amadora com interesse especial em pássaros.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Gerente de Produção",
                  "Nascido em Copenhague, filho de pais indianos, Vijay estudou matemática na Universidade do Sul da Dinamarca. Depois de se formar, trabalhou no fabricante dinamarquês de roupas de marca própria United Textile Group (UTG), especializado em métodos de produção ecológicos e sustentáveis. Ao perceber que um estilista progressista procurava alguém com experiência em manufatura, Vijay aproveitou a oportunidade para ganhar mais autonomia e assumir um papel mais criativo em uma empresa emergente. Apenas 10 pessoas trabalhavam para a KLÄDE quando Vijay ingressou, mas desde então ele tem sido um dos nossos principais defensores do crescimento e da expansão, sempre em busca de oportunidades negligenciadas ou ocultas.",
                  "Nas horas vagas, Vijay gosta de quebra-cabeças e jogos de tabuleiro.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Chefe de P&D e Cofundador",
                  "Originário de Malmö, na Suécia, Berndt mudou-se para o norte, para a Universidade de Luleå, para estudar Ciência dos Materiais. Foi aí que ele teve a ideia de criar materiais que mantivessem os usuários aquecidos e secos, ao mesmo tempo que eram leves e flexíveis o suficiente para permitir atividades extenuantes ao ar livre. Numa viagem de esqui a Hemsedal, na Noruega, conheceu Didier – e nasceu KLÄDE. O projeto de pós-graduação de Berndt levou à criação de um tecido flexível, leve, respirável, mas impermeável. Com a nova linha de inverno da KLÄDE, Berndt fez bom uso desse conhecimento, criando roupas práticas e elegantes para uma variedade de atividades em climas frios.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Diretor de Finanças",
                  "Luke estudou Finanças como parte de seu BBA em Administração de Empresas na BI Norwegian Business School, antes de dedicar algum tempo para seguir sua paixão por viagens. Enquanto estava em Cingapura, ele encontrou trabalho como analista de negócios júnior na Deloitte e lá permaneceu por três anos. Sentindo falta da família e de casa na Coreia do Sul, Luke regressou ao país com a expectativa de trabalhar para outra empresa multinacional de consultoria, até que uma nova empresa de vestuário disruptiva na Dinamarca captou o seu interesse. Aproveitando a oportunidade de trabalhar como parte de uma pequena equipe, a abordagem global, mas orientada para os detalhes, de Luke tem sido um trunfo inestimável para o crescimento da KLÄDE.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "Gerente de vendas de comércio eletrônico",
                  "Natural de Aalborg, na Dinamarca, Alma trabalhava com vendas de moda em Copenhague. Ela fez uma reciclagem, obteve um mestrado em Administração de Empresas, Sistemas de Informação e E-business pela Copenhagen Business School e conseguiu um emprego há alguns anos na KLÄDE, onde é responsável pelo canal de comércio eletrônico.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Presidente, Chefe de Design e Cofundador",
                  "Didier foi criado por seu pai francês e sua mãe marroquina em Chamonix, nos Alpes, onde aprendeu a esquiar logo depois de aprender a andar. Ele obteve um Diploma em Design de Moda na conceituada escola de moda ESMOD em Paris, França, e conheceu seu futuro parceiro de negócios enquanto trabalhava como instrutor de esqui na Noruega. Didier mora em Copenhague e ainda gosta de passear nas encostas dos Alpes.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Contacte-nos",
            formP1: "Complete o formulário abaixo.",
            formEmail: "E-mail",
            formSelect: "Selecione uma opção",
            formOption1: "Opção 1",
            formOption2: "Opção 2",
            formOption3: "Opção 3",
            formSubject: "Assunto",
            formTextarea: "Comentários",
            formSubmit: "Submeter",
          },
        },
      },
      //PTBR done
      {
        language: "PTBR",
        languageName: "PTBR",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Nova gama" },
            { item: "Nossa gama" },
            { item: "Nossa história" },
            { item: "Nossas campanhas" },
            { item: "Pessoas" },
            { item: "Contato" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Digite a senha: "
          },
        },
        route: {
          "/": {
            banner: "Visualize nossa nova gama",
            leftHeader1: "Conforto.",
            leftHeader2: "Elegância.",
            leftHeader3: "Inovação.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Estilo escandinavo.",
            rightHeader5: "Em qualquer lugar."
          },
          "/new-range": {
            banner: "Em breve",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Conforto versátil<br class='md:hidden' /> para<br class='hidden md:block'/> aventuras durante todo o ano</h4>",
            p1: "Fique atento à nossa próxima gama de roupas de inverno. No que diz respeito ao vestuário de inverno, a nossa nova gama utiliza os materiais mais avançados para manter os utilizadores confortáveis e flexíveis ao ar livre.",
            p2: "Nosso próprio tecido externo impermeável e respirável tem toda a versatilidade de uma jaqueta, enquanto as melhores penas puras proporcionam o calor e o conforto de uma jaqueta totalmente isolada.",
            p3: "Adequado para todas as atividades e profissões ao ar livre."
          },
          "/our-range": {
            heroHeader: "Nossa gama",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Visite nossa loja on-line para<br /> ver nossa gama completa de roupas<br /> e acessórios.</p>",
            heroBtn: "Visitar a loja",
            shippingHeader: "Expedição, logística e suprimentos",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS"  },
            ],
          },
          "/our-story": {
            heroHeader: "Nossa história",
            heroP: "A KLÄDE é o resultado de uma colaboração entre dois entusiastas do ski, Didier Rochereau e Berndt Långström, que uniram forças há dez anos para concretizar a sua visão de uma nova marca de roupa. O objetivo deles era simples: levar roupas e acessórios premium e duráveis para um mercado mais amplo e diversificado.",
            section1P1: "Nos primeiros dias da KLÄDE, Didier desenhou itens distintos e modernos, enquanto Berndt, no ambiente modesto de sua garagem, usou seu profundo conhecimento de materiais e tecidos para tornar os designs de Didier uma realidade. Juntos, eles criaram roupas elegantes, mas robustas, que não seriam vítimas dos hábitos descartáveis do fast fashion.",
            section1P2: "Combinando conforto e estilo, as primeiras linhas de produtos da KLÄDE foram suficientemente impressionantes para atrair o apoio local de modelos e atletas. A atenção da mídia e uma base de consumidores em expansão logo se seguiram, ajudando a transformar a marca na grande empresa que é hoje.",
            section2Header: "“Combinando conforto e estilo…”",
            section2P1: "Operamos agora a partir de uma fábrica e de escritórios em Herlev, na Dinamarca, bem como a partir da nossa loja principal no centro de Copenhagen e de várias outras lojas em todas as capitais escandinavas.",
            section2P2: "Mas este é apenas o começo da nossa jornada. ",
            section2P3: "Nos próximos anos, ampliaremos o nosso âmbito para incluir novas áreas de pesquisa e desenvolvimento, promovendo a nossa missão de fornecer vestuário elegante e prático a consumidores exigentes em todo o mundo.",
                },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl'>A KLÄDE NOS EUA </h4><p class='font-courierNew font-bold text-base text-white'>Clique nos pinos do mapa para ver os destinos que visitamos.</p>",
            mapItems: [
              { item: "Lago Tahoe" },
              { item: "Monte Park City" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Monte Okemo" }
            ],
            section1P1: "Junte-se a nós enquanto trazemos nossa nova linha de roupas de inverno para os EUA!",
            section1P2: " Os embaixadores da marca Kläde, Ferdie Zorrilla e Holly Beatrice-Wells, estão visitando algumas das estações de ski mais populares dos Estados Unidos para promover nossa nova linha. Em cada parada, por tempo limitado, estocaremos nossos itens mais recentes em lojas locais de roupas de esqui. ",
            section1P3: " Ao lançar a campanha em Aspen, Colorado, os embaixadores da nossa marca apareceram nos resorts próximos de Beaver Creek e Steamboat, antes de seguirem para Utah, Wyoming e Califórnia. Mais recentemente, eles apareceram no Okemo Mountain Resort em Vermont. ",
            section1Heading1: "Próxima parada:",
            section1Heading2: "sugarloaf, Maine.",
            section2P1: "Confira aqui as atualizações ou siga os embaixadores da nossa marca nas redes sociais:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • Há 8 horas</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Que maneira de começar nosso passeio! É um prazer fazer parceria com a Kläde, uma marca europeia que deve estar no seu radar. Vestindo suas jaquetas DIARIAMENTE. O trem da moda saiu da estação!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • Há 2 horas</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Adeus Aspen! Foi uma viagem louca!! Mal posso esperar para chegar às pistas do Maine! Vejo você lá?<span class='text-green'>#KlädeStyle #neve #montanhas# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Siga",
          },
          "/people": {
            heroHeader: "Nossa equipe",
            heroP: "Clique na imagem de cada pessoa para mais informações",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Diretor de Vendas e Marketing",
                  "Natural de Helsinque, Tiiu foi membro do Royal Swedish Ballet, antes de se reciclar como vendedor. Ela passou férias em Hemsedal, onde Didier era seu instrutor de esqui. Tiiu passou a trabalhar em Växjö para ajudar a melhorar a loja online da Outnorth AB, um grande varejista de roupas e equipamentos escandinavos para atividades ao ar livre. Recuperando-se rapidamente, ela passou a liderar a operação de comércio eletrônico após um ano. Quando Didier a contatou sobre a oportunidade de construir a presença online de uma nova empresa de moda, Tiiu estava pronta para trabalhar novamente em uma operação menor. Desde então, ela reinventou a estratégia de marketing e comunicação da KLÄDE e demonstrou ser adepta da construção de relacionamentos com clientes e empresas.",
                  "O herói de infância de Tiiu foi David Attenborough e hoje ela ainda é uma naturalista amadora com interesse especial em pássaros."
                ]
              },
              {
                name: "Vijay Basu",
                description: [
                  "Gerente de Produção",
                  "Nascido em Copenhague, filho de pais indianos, Vijay estudou matemática na Universidade do Sul da Dinamarca. Depois de se formar, trabalhou no fabricante dinamarquês de roupas de marca própria United Textile Group (UTG), especializado em métodos de produção ecológicos e sustentáveis. Ao perceber que um estilista progressista procurava alguém com experiência em manufatura, Vijay aproveitou a oportunidade para ganhar mais autonomia e assumir um papel mais criativo em uma empresa emergente. Apenas 10 pessoas trabalhavam para a KLÄDE quando Vijay ingressou, mas desde então ele tem sido um dos nossos principais defensores do crescimento e da expansão, sempre em busca de oportunidades negligenciadas ou ocultas.",
                  "Nas horas vagas, Vijay gosta de quebra-cabeças e jogos de tabuleiro."
                ]
              },
              {
                name: "Berndt Långström",
                description: [
                  "Chefe de P&D e Cofundador",
                  "Originário de Malmö, na Suécia, Berndt mudou-se para o norte, para a Universidade de Luleå, para estudar Ciência dos Materiais. Foi aí que ele teve a ideia de criar materiais que mantivessem os usuários aquecidos e secos, ao mesmo tempo que eram leves e flexíveis o suficiente para permitir atividades extenuantes ao ar livre. Numa viagem de esqui a Hemsedal, na Noruega, conheceu Didier – e nasceu KLÄDE. O projeto de pós-graduação de Berndt levou à criação de um tecido flexível, leve, respirável, mas impermeável. Com a nova linha de inverno da KLÄDE, Berndt fez bom uso desse conhecimento, criando roupas práticas e elegantes para uma variedade de atividades em climas frios."
                ]
              },
              {
                name: "Luke Joh",
                description: [
                  "Diretor de Finanças",
                  "Luke estudou Finanças como parte de seu BBA em Administração de Empresas na BI Norwegian Business School, antes de dedicar algum tempo para seguir sua paixão por viagens. Enquanto estava em Cingapura, ele encontrou trabalho como analista de negócios júnior na Deloitte e lá permaneceu por três anos. Sentindo falta da família e de casa na Coreia do Sul, Luke regressou ao país com a expectativa de trabalhar para outra empresa multinacional de consultoria, até que uma nova empresa de vestuário disruptiva na Dinamarca captou o seu interesse. Aproveitando a oportunidade de trabalhar como parte de uma pequena equipe, a abordagem global, mas orientada para os detalhes, de Luke tem sido um trunfo inestimável para o crescimento da KLÄDE."
                ]
              },
              {
                name: "Alma Andersen",
                description: [
                  "Gerente de vendas de comércio eletrônico",
                  "Natural de Aalborg, na Dinamarca, Alma trabalhava com vendas de moda em Copenhague. Ela fez uma reciclagem, obteve um mestrado em Administração de Empresas, Sistemas de Informação e E-business pela Copenhagen Business School e conseguiu um emprego há alguns anos na KLÄDE, onde é responsável pelo canal de comércio eletrônico."
                ]
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Presidente, Chefe de Design e Cofundador",
                  "Didier foi criado por seu pai francês e sua mãe marroquina em Chamonix, nos Alpes, onde aprendeu a esquiar logo depois de aprender a andar. Ele obteve um Diploma em Design de Moda na conceituada escola de moda ESMOD em Paris, França, e conheceu seu futuro parceiro de negócios enquanto trabalhava como instrutor de esqui na Noruega. Didier mora em Copenhague e ainda gosta de subir as encostas dos Alpes."
                ]
              }
            ]
          },
          "/contact": {
            formHeader: "Fale conosco",
            formP1: "Preencha o formulário abaixo.",
            formEmail: "Seu e-mail",
            formSelect: "Selecione sua opção",
            formOption1: "Opção 1",
            formOption2: "Opção 2",
            formOption3: "Opção 3",
            formSubject: "Assunto",
            formTextarea: "Comentários",
            formSubmit: "Enviar"
          },
        },
      },
      //RU done
      {
        language: "ru",
        languageName: "Russian",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Новая коллекция" },
            { item: "Наша коллекция" },
            { item: "Наша история" },
            { item: "Наши кампании" },
            { item: "Команда" },
            { item: "Контакты" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Введите пароль: "
          }
        },
        route: {
          "/": {
            banner: "Просмотр новой коллекции",
            leftHeader1: "Комфорт.",
            leftHeader2: "Изысканность.",
            leftHeader3: "Инновации.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "Скандинавский стиль.",
            rightHeader5: "В любой точке мира."
          },
          "/new-range": {
            banner: "Скоро",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Практичный комфорт<br class='md:hidden' /> для<br class='hidden md:block'/> приключений в любое время года</h4>",
            p1: "Следите за нашей новой коллекцией зимней одежды. В нашей новой линейке высококачественной зимней одежды используются самые современные материалы, обеспечивающие комфорт и свободу движения на открытом воздухе.",
            p2: "Наша инновационная водонепроницаемая, дышащая ткань обладает универсальностью ветровки, а тончайшие пуховые перья обеспечивают тепло и комфорт полностью утепленной куртки.",
            p3: "Подходит для всех профессий и видов активности на природе."
          },
          "/our-range": {
            heroHeader: "Наша коллекция",
      heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Посетите наш интернет-магазин, чтобы<br /> ознакомиться с полным ассортиментом<br /> одежды и аксессуаров.</p>",
      heroBtn: "Перейти в магазин",
      shippingHeader: "ОТПРАВКА ТОВАРОВ, ЛОГИСТИКА И ПОСТАВКИ",
      shippingItems: [
        { item: "PostNord"},
        { item: "DPD"},
        { item: "DHL"},
        { item: "FREJA"},
        { item: "Steinweg"},
        { item: "UPS"},
            ],
          },
          "/our-story": {
            heroHeader: "Наша история",
            heroP: "Kläde — это результат сотрудничества двух любителей лыжного спорта, Дидье Рошеро и Берндта Лонгстрема, которые десять лет назад объединили свои усилия, чтобы реализовать свое видение нового бренда одежды. Их цель была проста: вывести на массовый, более широкий рынок прочную, современную одежду и аксессуары класса люкс.",
            section1P1: "На заре существования Kläde Дидье показал свои инновационные модные наработки Берндту, который в скромных гаражных условиях использовал свои обширные знания материалов и тканей, чтобы воплотить идеи Дидье в реальность. Вместе они создали стильную и качественную одежду, которая не станет очередным примером бросовой «быстрой» моды.",
            section1P2: "Благодаря сочетанию комфорта и стиля первые коллекции KLÄDE сразу произвели впечатление, что позволило заручиться поддержкой местных моделей и спортсменов. Вскоре за этим последовало внимание средств массовой информации и расширение потребительской базы, что помогло бренду стать лидером, которым он является сегодня.",
            section2Header: "«Сочетание комфорта и стиля...»",
            section2P1: "Сейчас мы работаем на производственных и офисных площадках в Херлеве, Дания, открыли флагманский магазин в Копенгагене и несколько магазинов во всех столицах Скандинавии.",
            section2P2: "Но это только начало нашего пути. ",
            section2P3: "В ближайшие годы мы планируем расширить сферу своей деятельности в области исследований и разработок, выполняя свою миссию по производству стильной и практичной одежды для взыскательных потребителей по всему миру."
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE В<br /> США </h4><p class='font-courierNew font-bold text-base text-white'>Нажмите на отметки на карте, чтобы увидеть места, которые мы посетили.</p>",
            mapItems: [
              { item: "Озеро Тахо" },
              { item: "Парк-Сити Маунтин" },
              { item: "Аспен, Бивер-Крик, Стимбот-Спрингс" },
              { item: "Джексон-Хоул" },
              { item: "Окемо-Маунтин" }
            ],
            section1P1: "Наша новая зимняя коллекция в США — не пропустите!",
            section1P2: " Представители бренда Kläde Ферди Зоррилья и Холли Беатрис-Уэллс отправятся в поездку по самым популярным горнолыжным курортам США для продвижения нашей новой коллекции. В каждой локации в течение ограниченного времени вы сможете найти товары из нашей новой линейки в местных магазинах лыжной одежды. ",
            section1P3: " Запустив кампанию в Аспене, штат Колорадо, представители нашего бренда посетили близлежащие курорты Бивер-Крик и Стимбот-Спрингс и направились в Юту, Вайоминг и Калифорнию. Совсем недавно они посетили курорт Окемо-Маунтин в Вермонте. ",
            section1Heading1: "Следующий пункт назначения:",
            section1Heading2: "Шугарлоуф, штат Мэн.",
            section2P1: "Следите за обновлениями здесь или подписывайтесь на представителей нашего бренда в социальных сетях:",
            section2LeftP1: "Ферди Зоррилья",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 часов назад</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Какой старт у нашего тура! Рада объявить о партнерстве с Kläde, европейским брендом, который должен быть в вашем поле зрения. Я ношу их куртки КАЖДЫЙ день. Поехали!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Холли Беатрис-Уэллс",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 часа назад</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>До новых встреч, Аспен! Ну и приключение!! Спешим покорять склоны Мэна! Увидимся там?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "Читать",
          },
          "/people": {
            heroHeader: "Наша команда",
            heroP: "Нажмите на изображение каждого сотрудника, чтобы просмотреть подробную информацию",
            peopleItems: [
              {
                name: "Тиу Коккенен",
                description: [
                  "Директор по продажам и маркетингу",
                  "Уроженка Хельсинки, Тиу была участницей Шведского королевского балета, а затем переквалифицировалась на продавца. Она отдыхала в Хемседале, где Дидье был ее инструктором по лыжам. Тиу продолжил работу в Векшё, чтобы помочь улучшить интернет-магазин Outnorth AB, крупного розничного продавца скандинавской верхней одежды и снаряжения. Быстро встав на ноги, она уже через год возглавила подразделение электронной коммерции. Когда Дидье связался с ней по поводу возможности расширить присутствие новой модной компании в Интернете, Тиу была готова снова работать в меньшем бизнесе. С тех пор она заново изобрела маркетинговую и коммуникационную стратегию KLÄDE и показала себя специалистом в построении отношений с клиентами и предприятиями.",
                  "Героем детства Тиу был Дэвид Аттенборо, и сегодня она по-прежнему заядлый натуралист-любитель с особым интересом к птицам.",
                ],
              },
              {
                name: "Виджай Басу",
                description: [
                  "Менеджер по производству",
                  "Виджай родился в Копенгагене в семье индийцев и изучал математику в Университете Южной Дании. После окончания учебы он работал в датском производителе одежды под собственной торговой маркой United Textile Group (UTG), который специализируется на экологически чистых и устойчивых методах производства. Когда он увидел, что прогрессивный дизайнер одежды ищет человека с опытом производства, Виджай воспользовался шансом получить больше самостоятельности и занять более творческую роль в многообещающей компании. Когда Виджай присоединился к компании KLÄDE, работало всего 10 человек, но с тех пор он стал одним из наших ключевых сторонников роста и расширения, всегда ища упущенные из виду или скрытые возможности.",
                  "В свободное время Виджай любит разгадывать головоломки и настольные игры.",
                ],
              },
              {
                name: "Берндт Лонгстрем",
                description: [
                  "Руководитель отдела исследований и разработок и соучредитель",
                  "Родом из Мальмё, Швеция, Берндт переехал на север, в Университет Лулео, чтобы изучать материаловедение. Именно там у него возникла идея создать материалы, которые будут сохранять тепло и сухость, но при этом будут легкими и достаточно гибкими, чтобы выдерживать интенсивные нагрузки на свежем воздухе. Во время лыжной поездки в Хемседал, Норвегия, он встретил Дидье – и родился KLÄDE. Аспирантский проект Берндта привел к созданию гибкой, легкой, дышащей, но водонепроницаемой ткани. В новой зимней линейке KLÄDE Берндт нашел хорошее применение этим знаниям, создав практичную и стильную одежду для самых разных занятий в холодную погоду.",
                ],
              },
              {
                name: "Люк Чжо",
                description: [
                  "Финансовый директор",
                  "Люк изучал финансы в рамках программы делового администрирования BBA в Норвежской бизнес-школе BI, прежде чем потратить некоторое время на то, чтобы заняться своей страстью к путешествиям. Находясь в Сингапуре, он нашел работу младшим бизнес-аналитиком в компании Deloitte и проработал там три года. Потеряв семью и дом в Южной Корее, Люк вернулся туда в надежде работать в другой транснациональной консалтинговой фирме, пока его интерес не привлекла революционная компания по производству новой одежды в Дании. Люк наслаждается возможностью работать в небольшой команде. Его глобальный, но внимательный к деталям подход стал неоценимым активом для роста KLÄDE.",
                ],
              },
              {
                name: "Альма Андерсен",
                description: [
                  "Менеджер по продажам электронной коммерции",
                  "Уроженка Ольборга, Дания, Альма занималась продажей модной одежды в Копенгагене. Она прошла переподготовку, получила степень магистра наук в области делового администрирования, информационных систем и электронного бизнеса в Копенгагенской школе бизнеса и пару лет назад устроилась на работу в компанию KLÄDE, где она отвечает за канал электронной коммерции.",
                ],
              },
              {
                name: "Дидье Рошеро",
                description: [
                  "Президент, руководитель отдела дизайна и соучредитель",
                  "Дидье был воспитан отцом-французом и матерью-марокканкой в ​​Шамони в Альпах, где он научился кататься на лыжах вскоре после того, как научился ходить. Он получил диплом в области дизайна одежды в уважаемой школе моды ESMOD в Париже, Франция, и встретил своего будущего делового партнера, работая инструктором по лыжам в Норвегии. Дидье живет в Копенгагене и до сих пор любит кататься на склонах Альп.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Свяжитесь с нами",
            formP1: "Заполните форму.",
            formEmail: "Ваш адрес эл. почты",
            formSelect: "Выберите тип запроса",
            formOption1: "Вариант 1",
            formOption2: "Вариант 2",
            formOption3: "Вариант 3",
            formSubject: "Тема",
            formTextarea: "Текст",
            formSubmit: "Отправить",
          },
        },
      },
      //TK done
      {
        language: "tk",
        languageName: "Turkish",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "Yeni Koleksiyon" },
            { item: "Koleksiyonumuz" },
            { item: "Hikâyemiz" },
            { item: "Kampanyalarımız" },
            { item: "Çalışanlar" },
            { item: "İletişim" }
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "Parolayı girin: "
          },
        },
        route: {
          "/": {
          banner: "Yeni Koleksiyonumuzu Önizleyin",
          leftHeader1: "Rahatlık.",
          leftHeader2: "Şıklık.",
          leftHeader3: "Yenilik.",
          rightHeader1: ".",
          rightHeader2: ".",
          rightHeader3: ".",
          rightHeader4: "İskandinav Stili.",
          rightHeader5: "Her yerde."
          },
          "/new-range": {
            banner: "Çok yakında",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>Yıl boyu devam eden maceralar<br class='md:hidden' /> için<br class='hidden md:block'/> çok yönlü konfor</h4>",
            p1: "Yakında piyasaya sunulacak kışlık kıyafet koleksiyonumuza göz atın. Kışlık giyimin en üst noktasında yer alan yeni serimizde giyenleri açık havada rahat ve esnek tutmak için en gelişmiş malzemeler kullanılmıştır.",
            p2: "Dış kısmında kendi su geçirmez ve nefes alabilen kumaşımızdan üretilir ve koruyucu bir montun tüm çok yönlülüğüne sahiptir ve ayrıca kaliteli saf kuş tüyüyle tamamen yalıtımlı bir montun sıcaklığını ve konforunu sağlar.",
            p3: "Açık havada gerçekleştirilen tüm faaliyetler ve meslekler için uygundur."
          },
          "/our-range": {
            heroHeader: "Koleksiyonumuz",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>Tüm kıyafet ve aksesuar koleksiyonumuza göz atmak için<br /> çevrimiçi mağazamızı<br /> ziyaret edin.</p>",
            heroBtn: "Mağazayı Ziyaret Et",
            shippingHeader: "Sevkiyat, Lojistik ve Tedarik",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "Hikâyemiz",
            heroP: "KLÄDE, on yıl önce yeni bir giyim markası vizyonunu gerçekleştirmek için güçlerini birleştiren iki kayak tutkunu Didier Rochereau ve Berndt Långström arasındaki iş birliğinin sonucudur. Amaçları basitti. Dayanıklı ve lüks kıyafet ve aksesuarları daha geniş, daha çeşitli bir pazara sunmak.",
            section1P1: "KLÄDE'nin ilk günlerinde Didier özgün ve modaya uygun ürünler tasarlarken Berndt, garajının mütevazı ortamında Didier'in tasarımlarını hayata geçirmek için malzeme ve kumaşlar hakkındaki derin bilgisini kullandı. Birlikte, hızlı modanın kullanıp atma alışkanlıklarına kurban gitmeyecek şık ama sağlam kıyafetler yarattılar.",
            section1P2: "Rahatlık ve stili bir araya getiren KLÄDE'nin ilk ürün koleksiyonları, modellerin ve sporcuların yerel desteğini kazanacak kadar çarpıcıydı. Medyanın ilgisi ve gittikçe genişleyen tüketici tabanı kısa sürede markanın bugün olduğu gibi önemli bir oyuncu haline gelmesine yardımcı oldu.",
            section2Header: "“Konfor ve stil bir arada…”",
            section2P1: "Şu anda Danimarka, Herlev’deki üretim ve ofis sahamızın yanı sıra Kopenhag'ın merkezindeki imza mağazamız ve tüm İskandinav başkentlerindeki diğer mağazalarımızla faaliyet gösteriyoruz.",
            section2P2: "Ancak bu, yolculuğumuzun yalnızca başlangıcı. ",
            section2P3: "Gelecek yıllarda, kapsamımızı yeni araştırma ve geliştirme alanlarını içerecek şekilde genişleterek, dünyanın her yerindeki seçici tüketicilere şık ve pratik kıyafetler sunma misyonumuzu ilerleteceğiz."
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE<br /> ABD’de </h4><p class='font-courierNew font-bold text-base text-white'>Ziyaret ettiğimiz yerleri görmek için harita iğnelerine tıklayın.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1: "Yeni kışlık kıyafet koleksiyonumuzu ABD'ye getirme yolculuğumuza katılın!",
            section1P2: " Kläde marka elçileri Ferdie Zorrilla ve Holly Beatrice-Wells, yeni ürün koleksiyonumuzu tanıtmak için Amerika Birleşik Devletleri'ndeki en popüler kayak merkezlerinden bazılarını geziyor. Her durakta, en yeni ürünlerimizi yerel kayak giyim mağazalarında sadece sınırlı bir süre satışa sunacağız. ",
            section1P3: " Kampanyayı Aspen, Colorado'da başlatan marka elçilerimiz, Utah, Wyoming ve Kaliforniya'ya geçmeden önce yakındaki Beaver Creek ve Steamboat kayak merkezlerini ziyaret etti. Son olarak Vermont'taki Okemo Mountain Resort'ta göründüler. ",
            section1Heading1: "Bir sonraki durak:",
            section1Heading2: "Sugarloaf, Maine.",
            section2P1: "Güncellemeler için burayı kontrol edin veya marka elçilerimizi sosyal medyadan takip edin:",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 saat önce</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>Gezimize başlamak için ne güzel bir yol! Radarınızda olması gereken bir Avrupa markası olan Kläde ile ortaklık yaptığımız için mutluyuz Montlarını HER GÜN giyiyoruz. Heyecan treni istasyondan ayrıldı!!! <span class='text-green'>#KlädeStili #KlädeTuru</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 saat önce</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>Hoşça kal Aspen! Çılgın bir yolculuktu!! Maine’deki pistlere çıkmak için sabırsızlanıyorum! Orada görüşür müyüz?<span class='text-green'>#KlädeStili #kar #daglar# #Aspen #KlädeTuru</span</p>",
            section2CardBtn: "Takip Et"
          },
          "/people": {
            heroHeader: "Çalışanlarımız",
            heroP: "Daha fazla bilgi için her birinin resmine tıklayın",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "Satış ve Pazarlama Müdürü",
                  "Helsinki yerlisi olan Tiiu, satış elemanı olarak yeniden eğitim almadan önce İsveç Kraliyet Balesi'nin bir üyesiydi. Didier'in kayak hocası olduğu Hemsedal'da tatil yaptı. Tiiu, İskandinavya'nın büyük dış giyim ve ekipman perakendecisi Outnorth AB'deki çevrimiçi mağazanın geliştirilmesine yardımcı olmak için Växjö'de çalışmaya devam etti. Hızla ayağa kalktı ve bir yıl sonra e-ticaret operasyonunun liderliğine yükseldi. Didier, yeni bir moda şirketinin çevrimiçi varlığını oluşturma fırsatı konusunda onunla iletişime geçtiğinde Tiiu bir kez daha daha küçük bir operasyonda çalışmaya hazırdı. O günden bu yana KLÄDE'nin pazarlama ve iletişim stratejisini yeniden şekillendirdi ve müşteriler ve işletmelerle ilişkiler kurma konusunda usta olduğunu gösterdi.",
                  "Tiiu'nun çocukluk kahramanı David Attenborough'du ve bugün hala kuşlara özel ilgisi olan hevesli bir amatör doğa bilimcidir.",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "Üretim Müdürü",
                  "Kopenhag'da Hintli bir ailenin çocuğu olarak dünyaya gelen Vijay, Güney Danimarka Üniversitesi'nde matematik okudu. Mezun olduktan sonra yeşil ve sürdürülebilir üretim yöntemleri konusunda uzmanlaşmış Danimarkalı özel markalı giysi üreticisi United Textile Group'ta (UTG) çalıştı. İlerici bir giyim tasarımcısının üretim tecrübesi olan birini aradığını gören Vijay, daha fazla özerklik kazanma ve gelecek vaat eden bir şirkette daha yaratıcı bir rol üstlenme şansını yakaladı. Vijay katıldığında KLÄDE'de yalnızca 10 kişi çalışıyordu ama o zamandan beri büyüme ve genişlemenin temel savunucularımızdan biri oldu ve her zaman gözden kaçan veya gizli fırsatları araştırdı.",
                  "Boş zamanlarında Vijay bulmacalardan ve masa oyunlarından hoşlanıyor.",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "Ar-Ge Başkanı ve Kurucu Ortak",
                  "Aslen İsveç'in Malmö kentinden olan Berndt, Malzeme Bilimi eğitimi almak için kuzeydeki Luleå Üniversitesi'ne taşındı. Kullanıcıları sıcak ve kuru tutacak, aynı zamanda da zorlu dış mekan aktivitelerine izin verecek kadar hafif ve esnek malzemeler yaratma fikri orada ortaya çıktı. Norveç'in Hemsedal kentine yaptığı kayak gezisinde Didier ile tanıştı ve KLÄDE doğdu. Berndt'in lisansüstü projesi esnek, hafif, nefes alabilen ancak su geçirmez bir kumaşın yaratılmasına yol açtı. KLÄDE'nin yeni kış serisiyle Berndt, soğuk havalarda yapılan çeşitli aktiviteler için pratik, şık giysiler tasarlayarak bu bilgisinden yararlandı.",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "Finans Müdürü",
                  "Luke, seyahat tutkusunu sürdürmek için biraz zaman ayırmadan önce BI Norveç İşletme Okulu'nda İşletme BBA'sının bir parçası olarak Finans okudu. Singapur'dayken Deloitte'de Kıdemsiz İş Analisti olarak iş buldu ve üç yıl orada kaldı. Güney Kore'deki ailesini ve evini özleyen Luke, başka bir çokuluslu danışmanlık firmasında çalışma beklentisiyle oraya geri döndü, ta ki Danimarka'daki çığır açıcı yeni bir giyim şirketi onun ilgisini çekene kadar. Küçük bir ekibin parçası olarak çalışma şansının tadını çıkaran Luke'un küresel düşünceye sahip ancak ayrıntıya dayalı yaklaşımı, KLÄDE'nin büyümesi için paha biçilmez bir varlık oldu.",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "e-Ticaret Satış Müdürü",
                  "Danimarka'nın Aalborg kentinde doğan Alma, Kopenhag'da moda satışlarında çalışıyordu. Yeniden eğitim aldı, Kopenhag İşletme Okulu'ndan İşletme, Bilgi Sistemleri ve E-iş alanında yüksek lisans derecesi aldı ve birkaç yıl önce e-ticaret kanalından sorumlu olduğu KLÄDE'de işe girdi.",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "Başkan, Tasarım Başkanı ve Kurucu Ortak",
                  "Didier, Fransız babası ve Faslı annesi tarafından Alpler'deki Chamonix'te büyütüldü; burada yürümeyi öğrendikten kısa süre sonra kayak yapmayı da öğrendi. Fransa'nın Paris kentindeki saygın moda okulu ESMOD'da Moda Tasarımı Diploması aldı ve müstakbel iş ortağıyla Norveç'te kayak eğitmeni olarak çalışırken tanıştı. Didier Kopenhag'da yaşıyor ve hâlâ Alpler'deki yamaçlara tırmanmaktan keyif alıyor.",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "Bize ulaşın",
            formP1: "Aşağıdaki formu doldurun.",
            formEmail: "E-posta adresiniz",
            formSelect: "Seçeneğinizi belirleyin",
            formOption1: "1. Seçenek",
            formOption2: "2. Seçenek",
            formOption3: "3. Seçenek",
            formSubject: "Konu",
            formTextarea: "Yorumlar",
            formSubmit: "Gönder"
          },
        },
      },
      //ZHCN done
      {
        language: "zhcn",
        languageName: "英文",
        // "languageFlag": "img/english.png",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "新系列"},
            { item: "我们的产品系列" },
            { item: "我们的故事" },
            {item: "我们的宣传活动"},
            {item: "员工"},
            {item: "联系方式"},
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "输入密码： ",
          },
        },
        route: {
          "/": {
            banner: "抢先了解我们的新系列",
            leftHeader1: "舒适。",
            leftHeader2: "优雅。",
            leftHeader3: "创新。",
            rightHeader1: "。",
            rightHeader2: "。",
            rightHeader3: "。",
            rightHeader4: "斯堪的纳维亚风格。",
            rightHeader5: "任何地方。",
          },
          "/new-range": {
            banner: "即将推出",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>百搭，舒适，<br class='md:hidden' /><br class='hidden md:block'/>一年四季陪伴你走过冒险之旅</h4>",
            p1: "敬请期待我们即将推出的冬季服装系列。作为高端冬装，这个新系列使用最先进的材料制成，可令穿着者在户外感觉舒适灵活。",
            p2: "品牌自有的防水、透气外层面料具有防风外套的所有多样功能，而优质的纯正鸭绒让这件全隔离夹克更加温暖舒适。",
            p3: "适合所有户外活动和专业运动。",
          },
          "/our-range": {
            heroHeader: "我们的产品系列",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>访问我们的网店<br />查看所有服装<br />和配饰系列。</p>",
            heroBtn: "到访门店",
            shippingHeader: "配送、物流和供应商",
            shippingItems: [
              { item: "PostNord"},
              { item: "DPD"},
              { item: "DHL"},
              { item: "FREJA"},
              { item: "Steinweg"},
              { item: "UPS"},
            ],
          },
          "/our-story": {
            heroHeader: "我们的故事",
            heroP:
              "Kläde是两位滑雪爱好者Didier Rochereau和Berndt Långström联合打造的品牌，他们在十年前联手并且实现了对新服装品牌的愿望。他们的目标很简单：为更广阔、更多元市场带来耐用、优质的服装和配饰。",
            section1P1:
              "在Kläde的早期阶段，Didier负责提供独特的潮流设计，而Berndt在他朴素的车库中，运用自己对材料和织物的深入了解，让Didier的设计成为现实。他们一同创造了时尚但耐用的服装，而不是快时尚那样需要经常购买。",
            section1P2:
              "KLÄDE早期的产品线将舒适和时尚感相融合，十分引人注目，赢得了当地模特和运动员的支持。媒体的关注和不断扩大的消费者基础很快就随之而来，帮助该品牌成长为今天的主要市场参与者。",
            section2Header: "“将舒适和时尚感相融合……”",
            section2P1:
              "目前，我们在丹麦海莱乌设有生产基地和办事处，在哥本哈根市中心设有旗舰店，并在所有斯堪的纳维亚国家首都开设了门店。",
            section2P2: "但这只是我们旅程的开始。 ",
            section2P3:
              "在未来几年，我们将继续扩大范围，包括新的研究和开发领域，进一步履行我们为世界各地挑剔的消费者提供时尚、实用服装的使命。",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE<br />在美国</h4><p class='font-courierNew font-bold text-base text-white'>点击地图图钉来查看我们去过的地方。</p>",
            mapItems: [
              { item: "Lake Tahoe",},
              { item: "Park City Mountain",},
              { item: "Aspen，Beaver Creek，Steamboat",},
              { item: "Jackson Hole",},
              { item: "Okemo Mountain",},
            ],
            section1P1: "加入我们，我们将新的冬装系列带到美国！",
            section1P2:
              " Kläde品牌大使Ferdie Zorrilla和Holly Beatrice-Wells正在美国一些最受欢迎的滑雪胜地巡回宣传我们的新系列。每一站，我们都会在当地的滑雪服商店里限时出售最新产品。 ",
            section1P3:
              " 我们的品牌大使在科罗拉多州阿斯彭市开启这一活动，随后出现在附近的Beaver Creek和Steamboat滑雪度假村，然后会前往犹他州、怀俄明州和加利福尼亚州。最近，他们来到了佛蒙特州的Okemo Mountain度假村。 ",
            section1Heading1: "下一站：",
            section1Heading2: "缅因州，sugarloaf。",
            section2P1:
              "点击此处获取最新资讯，或者关注我们品牌大使的社交媒体账号：",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8小时前</p>",
            section2LeftP4:
              "<p class='font-helvetica mt-3 cardLeftText'>我们的旅行就这样开始了！很高兴与Kläde合作，这是一个你应该了解的欧洲品牌。每天都要穿他们的夹克。我已经出发了！！！<span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2小时前</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>再见了，阿斯彭！这是一次疯狂的旅程！已经等不及要去缅因州的雪坡上了！我们到时候见？<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "关注",
          },
          "/people": {
            heroHeader: "我们的员工",
            heroP: "点击每个人的照片即可了解更多信息",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "销售及营销总监",
                  "Tiiu 是赫尔辛基人，曾是瑞典皇家芭蕾舞团的成员，后来接受再培训成为销售人员。她在海姆瑟达尔度假，迪迪埃是她在那里的滑雪教练。 Tiiu 随后在韦克舍工作，帮助改进 Outnorth AB（斯堪的纳维亚户外服装和设备的大型零售商）的在线商店。一年后，她迅速站稳脚跟，升任电子商务业务负责人。当 Didier 联系她询问建立一家新时装公司在线业务的机会时，Tiiu 准备再次在一家规模较小的公司工作。此后，她重新制定了 KLäDE 的营销和传播策略，并展示了自己善于与客户和企业建立关系的能力。",
                  "Tiiu 儿时的英雄是大卫·阿滕伯勒 (David Attenborough)，如今她仍然是一位热心的业余博物学家，对鸟类特别感兴趣。",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "产品经理",
                  "维杰出生于哥本哈根，父母是印度人，在南丹麦大学学习数学。毕业后，他在丹麦自有品牌服装制造商联合纺织集团（UTG）工作，该公司专注于绿色和可持续的生产方法。当维杰看到一位进步的服装设计师正在寻找具有制造经验的人时，他抓住了机会，获得了更多的自主权，并在一家新兴公司中发挥了更具创造性的作用。 Vijay 加入时，KLäDE 只有 10 名员工，但从那时起，他一直是我们发展和扩张的主要倡导者之一，始终寻找被忽视或隐藏的机会。",
                  "在业余时间，维杰喜欢拼图和棋盘游戏。",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "研发主管兼联合创始人",
                  "伯恩特来自瑞典马尔默，后来北上吕勒奥大学学习材料科学。正是在那里，他萌生了创造一种材料的想法，这种材料可以让穿着者保持温暖和干燥，同时又足够轻且柔韧，可以进行剧烈的户外活动。在一次前往挪威海姆瑟达尔的滑雪之旅中，他遇到了 Didier，KLADE 就此诞生。 Berndt 的研究生项目创造了一种灵活、轻便、透气且防水的织物。 Berndt 凭借 KLäDE 的全新冬季系列充分利用了这些知识，为一系列寒冷天气的活动设计了实用、时尚的服装。",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "财务总监",
                  "Luke 在 BI 挪威商学院攻读工商管理 BBA 课程时学习了金融学，之后又抽出一些时间去追求他对旅行的热情。在新加坡期间，他在德勤找到了一份初级业务分析师的工作，并在那里工作了三年。卢克在韩国失去了家人和家园，回到韩国，希望能在另一家跨国咨询公司工作，直到丹麦一家颠覆性的新服装公司引起了他的兴趣。 Luke 很享受在小团队中工作的机会，他具有全球视野且注重细节的方法对于 KLäDE 的发展来说是一笔宝贵的财富。",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "电商销售经理",
                  "阿尔玛是丹麦奥尔堡人，在哥本哈根从事时装销售工作。她接受了再培训，获得了哥本哈根商学院工商管理、信息系统和电子商务理学硕士学位，并于几年前在 KLäDE 找到了一份工作，负责他们的电子商务渠道。",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "总裁、设计主管兼联合创始人",
                  "迪迪埃由法国父亲和摩洛哥母亲在阿尔卑斯山的夏蒙尼抚养长大，在那里他学会走路后不久就学会了滑雪。他在法国巴黎著名的 ESMOD 时装学校获得了时装设计文凭，并在挪威担任滑雪教练时认识了他即将成为的商业伙伴。 Didier 住在哥本哈根，仍然喜欢去阿尔卑斯山滑雪。",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "联系我们",
            formP1: "填写下方的表格。",
            formEmail: "你的电子邮箱地址",
            formSelect: "选择你的方式",
            formOption1: "方式1",
            formOption2: "方式2",
            formOption3: "方式3",
            formSubject: "主题",
            formTextarea: "评论",
            formSubmit: "提交",
          },
        },
      },
      //ZHTW done
      {
        language: "zhtw",
        languageName: "English",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "新系列" },
            { item: "我們的系列" },
            { item: "我們的故事" },
            { item: "我們的行銷活動" },
            { item: "人員" },
            { item: "聯絡人" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "輸入密碼： ",
          },
        },
        route: {
          "/": {
            banner: "預覽我們的新系列",
            leftHeader1: "舒適。",
            leftHeader2: "高雅。",
            leftHeader3: "創新。",
            rightHeader1: "。",
            rightHeader2: "。",
            rightHeader3: "。",
            rightHeader4: "北歐風格。",
            rightHeader5: "任何地點。",
          },
          "/new-range": {
            banner: "即將推出",
            header: "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>終年探險<br class='md:hidden' />的<br class='hidden md:block'/>多樣舒適</h4>",
            p1: "請留意我們即將推出的冬季服飾系列。在高級的冬裝市場中，我們的新系列採用最先進的材料，讓穿戴者在戶外保持舒適和彈性。",
            p2: "我們特有的防水、透氣外層布料擁有防水外套的所有功能，同時最細緻的純毛羽絨可提供完全隔熱夾克的保暖和舒適。",
            p3: "適合所有戶外活動和職業。",
          },
          "/our-range": {
            heroHeader: "我們的系列",
            heroP: "<p class='text-white font-courierNew text-base font-bold mb-4'>造訪我們的網路商店<br />查看全系列的服裝<br />和配件。</p>",
            heroBtn: "造訪店面",
            shippingHeader: "運送、物流以及供應",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "我們的故事",
            heroP: "KLÄDE 是由二位滑雪愛好者 Didier Rochereau 和 Berndt Långström 協同創立，在 10 年前聯手實現了他們對於新服飾品牌的願景。他們的目標很簡單：為更廣大、更多樣的市場帶來耐穿、優質的衣服和配件。",
            section1P1: "KLÄDE 的早期是由 Didier 設計獨特的流行商品，Berndt 則運用他對材質和布料的高深知識，在他的簡單車庫中實現 Didier 的設計。他們聯手打造了流行卻耐穿的服裝，而不至於成為快時尚丟棄習慣下的受害者。",
            section1P2: "結合舒適和時尚，KLÄDE 早期的吸睛產品線得到了當地模特兒和運動員的喜愛。媒體的注意力和越來越多的客群很快地隨之而來，幫助這個品牌成長茁壯到今天的領先地位。",
            section2Header: "「結合舒適和時尚…」",
            section2P1: "現在我們在生產和辦公室所在地的丹麥赫爾萊夫、哥本哈根市中心的旗艦店，以及其他幾個北歐國家首都的店面營運。",
            section2P2: "不過這只是我們旅程的開始。 ",
            section2P3: "接下來幾年，我們將會拓寬範疇以納入新的研發領域，進一步實現我們的使命，為全球高標準的消費者提供時尚、實用的服裝。",
          },
          "/our-campaigns": {
            heroContent: "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE 在<br />美國 </h4><p class='font-courierNew font-bold text-base text-white'>點擊地圖上的圖釘，瀏覽我們造訪過的目的地。</p>",
            mapItems: [
              { item: "太浩湖" },
              { item: "帕克城山" },
              { item: "亞斯本、海狸溪、斯廷博特" },
              { item: "傑克遜霍爾" },
              { item: "奧克莫山" },
            ],
            section1P1: "在我們將全新冬季服飾系列帶到美國時，一起加入我們！",
            section1P2: " Kläde 的品牌大使 Ferdie Zorrilla 和 Holly Beatrice-Wells 正在美國一些最受歡迎的滑雪渡假勝地推廣我們的新系列。每一站，我們將會在當地的滑雪服飾商店限時推出我們的最新品項。 ",
            section1P3: " 我們的品牌大使正在科羅拉多州的亞斯本啟動這項行銷活動，在出發前往猶他州、懷俄明州和加州之前，他們會在附近的海狸溪和斯廷博特渡假村現身。最近他們出現在佛蒙特州的奧克莫山。 ",
            section1Heading1: "下一站：",
            section1Heading2: "緬因州的舒格洛夫。",
            section2P1: "在此查看最新消息，或是在社群媒體上追蹤我們的品牌大使：",
            section2LeftP1: "Ferdie Zorrilla",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • 8 小時前</p>",
            section2LeftP4: "<p class='font-helvetica mt-3 cardLeftText'>我們的旅程就這樣開始了！很開心和 Kläde 合作，這是你應該要關注的歐洲品牌。「每天」穿著他們的外套。熱銷列車已經出發！！！<span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "Holly Beatrice-Wells",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3: "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • 2 小時前</p>",
            section2RightP4: "<p class='font-helvetica mt-3 cardRightText'>再會了，亞斯本！這趟滑得太瘋狂了！等不及要挑戰緬因州的滑雪道！到時候見？<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "追蹤",
          },
          "/people": {
            heroHeader: "我們的員工",
            heroP: "點擊每個人的照片以取得更多資訊",
            peopleItems: [
              {
                name: "Tiiu Kokkenen",
                description: [
                  "銷售與市場總監",
                  "Tiiu是赫爾辛基人，在重新訓練成為銷售人員之前，曾是瑞典皇家芭蕾舞團的成員。 她在Hemsedal度假時，Didier是她的滑雪教練。 Tiiu繼續在Växjö工作，幫助改善Outnorth AB的網路商店，這是一家斯堪的納維亞戶外服裝和裝備的大型零售商。 一年後，她迅速站穩腳跟，開始領導電子商務營運。 當Didier聯絡她，希望有機會建立一家新時尚公司的網站時，Tiiu已做好準備再次在一家規模較小的公司工作。 後來，她重新制定了KLÄDE的行銷和傳播策略，並且展現出善於與客戶和企業建立關係的能力。",
                  "Tiiu兒時的英雄是David Attenborough，到現在她還是積極的業餘自然主義者，對鳥類特別感興趣。",
                ],
              },
              {
                name: "Vijay Basu",
                description: [
                  "生產經理",
                  "Vijay出生於哥本哈根，父母是印度人，在南丹麥大學學習數學。 畢業後，他在丹麥自有品牌服裝製造商UTG工作，該集團專門從事綠色和永續性的生產方式。 當他看到一位積極的服裝設計師正在尋求有製造經驗的人時，Vijay抓住機會得到更大的自主權，並且在一家成長的新公司中得到更具有創意的職位。 Vijay加入時，只有10人在KLÄDE工作，但從那時起，他一直是我們成長和擴展的主要宣導者之一，總是在尋找被忽視或隱藏的機會。",
                  "空閒時，Vijay喜歡玩拼圖和桌遊。",
                ],
              },
              {
                name: "Berndt Långström",
                description: [
                  "研發主管兼並同創辦人",
                  "Berndt來自瑞典馬爾摩，搬到北邊的Luleå大學就讀材料科學。 他在那裡想到要創造可以讓穿戴者保持溫暖和乾燥的材質，同時又够輕盈且具有彈性，足以進行劇烈的戶外活動。 在一次去挪威海姆瑟達爾的滑雪旅行中，他遇到了Didier – KLÄDE就此誕生。 Berndt的研究生專案創造出一種靈活、輕便、透氣但防水的布料。 憑藉KLÄDE的新冬季系列，Berndt善用那些知識，為一系列寒冷天氣的娛樂活動設計了實用、時尚的服裝。",
                ],
              },
              {
                name: "Luke Joh",
                description: [
                  "財務總監",
                  "Luke就讀財務金融系，是BI挪威商學院商學士學位的一部分，然後抽出一些時間來追求他對旅行的熱情。 在新加坡期間，他在勤業眾信找到了一份初級商業分析師的工作，並在那裡呆了三年。因為思念在韓國的家人和家，Luke帶著為另一家跨國顧問公司工作的期望回到了韓國，直到丹麥一間具顛覆性的新創丹麥服裝公司引起了他的興趣。 Luke很珍惜在小團隊工作的機會，他的全球思維但又重視細節的做事方法，對KLÄDE的成長來說是非常寶貴的資產。",
                ],
              },
              {
                name: "Alma Andersen",
                description: [
                  "電子商務業務經理",
                  "Alma是丹麥奧爾堡人，曾在哥本哈根從事時裝銷售工作。 她重新訓練、取得哥本哈根商學院工商管理、資訊系統和電子商務理學碩士學位，然後幾年前拿到KLÄDE的工作，負責他們的電子商務通路。",
                ],
              },
              {
                name: "Didier Rochereau",
                description: [
                  "總裁、設計主管兼共同創辧人",
                  "Didier在阿爾卑斯山的夏慕尼由他的法國父親和摩洛哥母親撫養長大，在那裡他學會走路後不久就學會滑雪。 他在法國巴黎評價很高的時尚學校ESMOD取得服裝設計文憑，並在挪威擔任滑雪教練時遇到即將成為他商業夥伴的人。 Didier住在哥本哈根，仍然喜歡去阿爾卑",
                ],
              },
            ],
          },
          "/contact": {
            formHeader: "聯絡我們",
            formP1: "完成以下表格。",
            formEmail: "您的電子郵件",
            formSelect: "選擇您的選項",
            formOption1: "選項 1",
            formOption2: "選項 2",
            formOption3: "選項 3",
            formSubject: "主旨",
            formTextarea: "評論",
            formSubmit: "送出",
          },
        },
      },
      //HE done
      {
        language: "he",
        languageName: "Hebrew",
        languageFlag: "img/english.png",
        updated: "07/08/2023",
        global: {
          langChoose: "Choose your language:",
          langSelectorItems: [
            {
              lang: "English",
              text: "English EN",
            },
            {
              lang: "Spanish",
              text: "Español ES",
            },
            {
              lang: "Colombian Spanish",
              text: "Español Colombiano ESCO",
            },
            {
              lang: "French",
              text: "Français FR",
            },
            {
              lang: "German",
              text: "Deutsch DE",
            },
            {
              lang: "Italian",
              text: "Italiano IT",
            },
            {
              lang: "Netherlands",
              text: "Nederlands NL",
            },
            {
              lang: "Japanese",
              text: "日本 JA",
            },
            {
              lang: "Korean",
              text: "韓国語 KO",
            },
            {
              lang: "Polish",
              text: "Polski PL",
            },
            {
              lang: "Portuguese",
              text: "Português PT",
            },
            {
              lang: "Brazilian Portuguese",
              text: "Portugues do Brasil PTBR",
            },
            {
              lang: "Russian",
              text: "Pусский RU",
            },
            {
              lang: "Turkish",
              text: "Türkçe TK",
            },
            {
              lang: "Simplified Chinese",
              text: "简体中文 ZHCN",
            },
            {
              lang: "Taiwanese Mandarin",
              text: "國語 ZHTW",
            },
            {
              lang: "Hebrew",
              text: "עִברִית HE ",
            },
          ],
          navItems: [
            { item: "טווח חדש"},
            { item: "הטווח שלנו" },
            { item: "הסיפור שלנו" },
            { item: "הקמפיינים שלנו" },
            { item: "אנשים" },
            { item: "איש קשר" },
          ],
        },
        component: {
          SiteLogin: {
            brand: "KLÄDE",
            inst: "נא להזין סיסמה:",
          },
        },
        route: {
          "/": {
            banner: "גלו בתצוגה מקדימה את הטווח החדש שלנו",
            leftHeader1: "נוחות.",
            leftHeader2: "אלגנטיות.",
            leftHeader3: "חדשנות.",
            rightHeader1: ".",
            rightHeader2: ".",
            rightHeader3: ".",
            rightHeader4: "סגנונן סקנדינבי.",
            rightHeader5: "בכל מקום.",
          },
          "/new-range": {
            banner: "בקרוב",
            header:
              "<h4 class='absolute bottom-5 text-white font-bebas text-5xl md:text-7xl'>נוחות ורסטילית<br class='md:hidden' /> שתהיה מושלמת<br class='hidden md:block'/> להרפתקאות בכל עונות השנה</h4>",
            p1: "בקרוב: קו בגדי החורף שלנו. אופנת עילית לחורף. הקו חדש שלנו עושה שימוש בחומרים המתקדמים ביותר, שמספקים למשתמשים נוחות וגמישות בכל הפעילויות בחוץ.",
            p2: "בד חיצוני עמיד במים ונושם שפיתחנו בעצמנו - עם כל התכונות הוורסטיליות של מעיל עליון - ונוצות פוך טהורות מהאיכות הטובה ביותר יספקו לכם חום ונוחות של מעיל עם בטנת בידוד מלאה.",
            p3: "מותאם לכל הפעילויות ולכל העיסוקים בחוץ.",
          },
          "/our-range": {
            heroHeader: "הטווח שלנו",
            heroP:
              "<p class='text-white font-courierNew text-base font-bold mb-4'>בקרו בחנות האינטרנט שלנו כדי<br /> לראות את כל קו הבגדים<br /> והאביזרים שלנו.</p>",
            heroBtn: "לביקור בחנות",
            shippingHeader: "משלוחים, לוגיסטיקה ואספקה",
            shippingItems: [
              { item: "PostNord" },
              { item: "DPD" },
              { item: "DHL" },
              { item: "FREJA" },
              { item: "Steinweg" },
              { item: "UPS" },
            ],
          },
          "/our-story": {
            heroHeader: "הסיפור שלנו",
            heroP:
              "Kläde היא תוצאה של שיתוף פעולה בין שני חובבי סקי, דידייה רושרו וברנדט לנגסטרום, שחברו לפני עשר שנים כדי להגשים את החזון שלהם: מותג בגדים חדש. הייתה להם מטרה פשוטה אחת: להציע בגדים ואביזרים איכותיים ועמידים לשוק רחב ומגוון יותר.",
            section1P1:
              "בתחילת הדרך של KLÄDE, דידייה עיצב פריטים ייחודיים וטרנדיים וברנדט, מן המחסן הצנוע שלו, השתמש בידע המעמיק שצבר לגבי חומרים ואריגים כדי להפוך את העיצובים של דידייה למציאות. ביחד, הם יצרו בגדים אלגנטיים ועמידים שלא יפלו קורבן להרגלי ההשלכה של האופנה המהירה.",
            section1P2:
              "בשילוב של נוחות וסגנון, קווי המוצרים המוקדמים של KLÄDE בלטו מספיק ומשכו תמיכה מקומית מדוגמנים וספורטאים. תשומת לב מהתקשורת ובסיס לקוחות מתרחב הגיעו זמן קצר לאחר מכן וסייעו בצמיחת המותג למעמדו כשחקן מרכזי בשוק כיום.",
            section2Header: "“שילוב של נוחות וסטייל...”",
            section2P1:
              "אנחנו פועלים כיום מאתר המשרדים והייצור שלנו בהרלב שבדנמרק, מחנות הדגל שלנו במרכז קופנהגן ומחנויות נוספות בכל ערי הבירה הסקנדינביות.",
            section2P2: "אבל זוהי רק תחילת הדרך שלנו.",
            section2P3:
              "בשנים הבאות, אנחנו נרחיב את היקף הפעילות שלנו ונכלול בו תחומי מחקר ופיתוח חדשים, שיקדמו את המשימה שלנו להציע בגדים אלגנטיים ושימושיים ללקוחות מביני עניין בכל העולם.",
          },
          "/our-campaigns": {
            heroContent:
              "<h4 class='text-white font-bebas text-5xl md:text-7xl'>KLÄDE <br /> בארה'ב </h4><p class='font-courierNew font-bold text-base text-white'>לחצו על הסיכות במפה כדי לראות יעדים שבהם ביקרנו.</p>",
            mapItems: [
              { item: "Lake Tahoe" },
              { item: "Park City Mountain" },
              { item: "Aspen, Beaver Creek, Steamboat" },
              { item: "Jackson Hole" },
              { item: "Okemo Mountain" },
            ],
            section1P1:
              "הצטרפו אלינו למסע שבו נציג את קו בגדי החורף החדש שלנו בארצות הברית!",
            section1P2:
              " שגרירי המותג של Kläde פרדי זורילה והולי ביאטריס-וולס מסיירים בכמה מאתרי הסקי הכי פופולריים בארצות הברית כדי לקדם את הקו החדש שלנו. בכל תחנה, ולזמן מוגבל בלבד, אנחנו נצייד את חנויות בגדי הסקי המקומיות בעיצובים החדשים שלנו.",
            section1P3:
              " אחרי שהשיקו את הקמפיין באספן קולורדו, שגרירי המותג שלנו הגיעו לאתרי הנופש בביבר קריק וסטימבואט הקרובים ולאחר מכן המשיכו ליוטה, ויומינג וקליפורניה. התחנה האחרונה הייתה באתר הר אוקימו בוורמונט.",
            section1Heading1: "התחנה הבאה:",
            section1Heading2: "sugarloaf, Maine.",
            section2P1:
              "לחצו כאן לעדכונים, או עקבו אחרי שגרירי המותג שלנו ברשתות החברתיות:",
            section2LeftP1: "פרדי זורילה",
            section2LeftP2: "fernando_zorrilla18",
            section2LeftP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>fernando_zorrilla18</span> • לפני 8 שעות</p>",
            section2LeftP4:
              "<p class='font-helvetica mt-3 cardLeftText'>איזו דרך מדהימה להתחיל את הסיור שלנו! שמח להיות שותף של Kläde, מותג אירופאי שצריך להיות על הכוונת שלכם. לובש את המעילים שלהם כל יום. רכבת ההייפ יצאה מהתחנה!!! <span class='text-green'>#KlädeStyle #KlädeTour</span></p>",
            section2RightP1: "הולי ביאטריס-וולס",
            section2RightP2: "HBWxoutofboundsx",
            section2RightP3:
              "<p class='text-xs w-[48%] font-helvetica'><span class='text-green'>HBWxoutofboundsx</span> • לפני שעתיים</p>",
            section2RightP4:
              "<p class='font-helvetica mt-3 cardRightText'>להתראות אספן! זה היה מרגש!! מתה כבר לגלוש במדרונות של מיין! נתראה שם?<span class='text-green'>#KlädeStyle #snow #mountains# #Aspen #KlädeTour</span</p>",
            section2CardBtn: "לעקוב",
          },
          "/people": {
            heroHeader: "הצוות שלנו",
            heroP: "לחצו על התמונה של כל אדם כדי לגלות פרטים נוספים",
            peopleItems: [
              {
                name: "טיו קוקנן",
                description: [
                  "מנהל מכירות ושיווק",
                  "יליד הלסינקי, טייו היה חבר בבלט השבדי המלכותי, לפני הסבה מקצועית כאיש מכירות. היא נסעה בהמסדל, שם דידייה היה מדריך הסקי שלה. Tiiu המשיכה לעבוד ב-Vaxjö כדי לעזור לשפר את החנות המקוונת ב-Outnorth AB, קמעונאית גדולה של ביגוד וציוד חוצות סקנדינביה. כשמצאה את רגליה במהירות, היא קמה להוביל את מבצע המסחר האלקטרוני לאחר שנה אחת. כאשר דידייה יצר איתה קשר בנוגע להזדמנות לבנות נוכחות מקוונת של חברת אופנה חדשה, Tiiu הייתה מוכנה לעבוד שוב במבצע קטן יותר. מאז היא המציאה מחדש את אסטרטגיית השיווק והתקשורת של KLÄDE והראתה את עצמה מיומנת בבניית קשרים עם לקוחות ועסקים.",
                  "גיבור ילדותו של טייו היה דייוויד אטנבורו וכיום היא עדיין חוקרנית חובבת נלהבת עם עניין מיוחד בציפורים.",
                ],
              },
              {
                name: "ויג'אי באסו",
                description: [
                  "מנהל ייצור",
                  "ויג'אי, שנולד בקופנהגן להורים הודים, למד מתמטיקה באוניברסיטת דרום דנמרק. לאחר סיום לימודיו הוא עבד ביצרנית הבגדים הפרטית הדנית United Textile Group (UTG), המתמחה בשיטות ייצור ירוקות ובני קיימא. כשראה שמעצב בגדים מתקדם מחפש מישהו עם ניסיון בייצור, ויג'אי ניצל את ההזדמנות להשיג יותר אוטונומיה ולקחת תפקיד יצירתי יותר בחברה מתפתחת. רק 10 אנשים עבדו עבור KLÄDE כאשר ויג'אי הצטרף, אבל מאז הוא היה אחד התומכים העיקריים שלנו לצמיחה והתרחבות, תמיד מחפש הזדמנויות שנשכחו או נסתרות.",
                  "בזמנו הפנוי, ויג'אי נהנה מחידות ומשחקי לוח.",
                ],
              },
              {
                name: "ברנדט לנגסטרום",
                description: [
                  'ראש מו"פ ומייסד שותף',
                  "במקור ממאלמו, שבדיה, ברנדט עבר צפונה לאוניברסיטת לולה כדי ללמוד מדעי החומרים. שם היה לו הרעיון ליצור חומרים שישמרו על לובשים חמים ויבשים תוך שהם קלים וגמישים מספיק כדי לאפשר פעילות מאומצת בחוץ. בטיול סקי בהמסדאל, נורבגיה, הוא פגש את דידייה - ו-KLÄDE נולד. הפרויקט לאחר התואר של ברנדט הוביל ליצירת בד גמיש, קל משקל, נושם, אך עמיד למים. עם טווח החורף החדש של KLÄDE, ברנדט השתמש בידע הזה על ידי עיצוב בגדים פרקטיים ומסוגננים למגוון עיסוקים במזג אוויר קר.",
                ],
              },
              {
                name: "לוק ג'ו",
                description: [
                  "מנהל פיננסי",
                  "לוק למד פיננסים כחלק מה-BBA שלו במנהל עסקים בבית הספר BI Norwegian Business, לפני שלקח קצת פסק זמן כדי להמשיך את התשוקה שלו לטיולים. בעודו בסינגפור, הוא מצא עבודה כאנליסט עסקי זוטר בדלויט ונשאר שם שלוש שנים. בהיעדר משפחה ובית בדרום קוריאה, לוק חזר לשם בציפייה לעבוד בחברת ייעוץ רב לאומית אחרת, עד שחברת בגדים חדשה ומפרעת בדנמרק תפסה את העניין שלו. התענגות על ההזדמנות לעבוד כחלק מצוות קטן, הגישה הגלובלית אך מונעת הפרטים של לוק הייתה נכס לא יסולא בפז לצמיחה של KLÄDE.",
                ],
              },
              {
                name: "אלמה אנדרסן",
                description: [
                  "מנהל מכירות מסחר אלקטרוני",
                  "אלמה, ילידת אלבורג, דנמרק, הייתה במכירות אופנה בקופנהגן. היא עשתה הסבה מקצועית, קיבלה תואר שני במנהל עסקים, מערכות מידע ועסקים אלקטרוניים מבית הספר למנהל עסקים בקופנהגן ולקחה עבודה לפני כמה שנים ב-KLÄDE, שם היא אחראית על ערוץ המסחר האלקטרוני שלהם.",
                ],
              },
              {
                name: "דידייה רושרו",
                description: [
                  "נשיא, ראש תחום עיצוב ומייסד שותף",
                  "דידייה גדל על ידי אביו הצרפתי ואמו המרוקאית בשאמוני שבהרי האלפים, שם למד סקי זמן קצר לאחר שלמד ללכת. הוא קיבל תעודה בעיצוב אופנה בבית הספר המוערך לאופנה ESMOD בפריז, צרפת, והכיר את שותפו העסקי שעתיד להיות בקרוב בזמן שעבד כמדריך סקי בנורבגיה. דידייה גר בקופנהגן ועדיין נהנה לעלות על המדרונות במעלה האלפים.",
                ],
              },
              ],
          },
          "/contact": {
            formHeader: "צרו קשר",
            formP1: "השלימו את הטופס הבא.",
            formEmail: 'כתובת הדוא"ל שלך',
            formSelect: "נא לבחור אפשרות",
            formOption1: "1 אפשרות",
            formOption2: "2 אפשרות",
            formOption3: "3 אפשרות",
            formSubject: "נושא",
            formTextarea: "הערות",
            formSubmit: "שליחה",
          },
        },
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
};

export default languages;
