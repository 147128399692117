const i18n = {
    methods: {
        changeLanguage (langNo) {
            console.log(langNo);
            this.$store.state.currentLangNo = langNo;
        }
    },
    computed: {
        i18n () {
            return this.$store.getters.language
        },
        i18n_g () {
            return this.$store.getters.language.global
        },
        i18n_r () {
            return this.i18n.route[this.$route.path]
        },
        i18n_c () {
            return this.i18n.component[this.$options.componentID]
        },
        i18n_n () {
            return this.i18n.component[this.$options.name]
        },
        langG () {
            return this.i18n_g
        },
        langC () {
            return this.i18n_n
        },
        langR () {
            return this.i18n_r
        }
    }
}

export default i18n