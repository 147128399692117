<template>
<Transition>
  <div v-if="userHasLoggedIn">
    <PageNav />
    <router-view />
  </div>
  <div v-else>
    <SiteLogin />
  </div>
</Transition>

</template>

<script>
import PageNav from '@/components/Nav.vue'
import SiteLogin from '@/components/Login.vue'

export default {
  components: {
    PageNav,
    SiteLogin 
  },
  computed: {
    userHasLoggedIn(){
      return this.$store.state.userHasLoggedIn;
    }
  }
}
document.title = "KLÄDE"
</script>


<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
