<template>
    <div class="fixed top-0 left-0 z-10 bg-blue flex flex-col items-center justify-center w-screen h-screen">
        <h1 class="font-bebas text-white text-8xl">{{ langC.brand }}</h1>
        <div class="flex items-center">
            <p class="font-courierNew text-white font-bold mr-5">{{ langC.inst }}</p>
            <div class="flex">
                <input v-on:keyup.enter="handlePasswordSubmit" class="mr-3 passwordInput" type="password" name="password" :maxlength="passwordMaxLength" required>
                <img @click="handlePasswordSubmit" class="cursor-pointer" src="@/assets/img/white-triangle-right.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>

export default {
  props: {
    page: String
  },
  data(){
	return {
		password: "123",
		passwordContact: "SNEFALD",
	}
  },
  name: 'SiteLogin',
  components: {
  },
  computed: {
    passwordMaxLength(){
        if(this.page === "contact"){
            return 7;
        }
        return 3;
    }
  },
  methods: {
    handlePasswordSubmit(){
        const submitted = document.querySelector(".passwordInput").value.toLowerCase();
        const correctPassword = this.password.toLowerCase();
        const correctPasswordContact = this.passwordContact.toLowerCase();

        if(this.page === "contact"){
            if(submitted === correctPasswordContact){
                console.log("yes contact");
                this.$store.commit('GRANT_USER_CONTACT_ACCESS');
            }else {
                console.log("no contact");
            }
        }else {
            if(submitted === correctPassword){
                console.log("yes not contact");
                this.$store.commit('GRANT_USER_SITE_ACCESS');
            }else {
                console.log("no not contact");
            }
        }

    }
  }
}
</script>

<style scoped>
    .passwordInput {
        letter-spacing: 1.5vw;
        font-family: Verdana;
        font: small-caption;
        font-size: 26px;
        color: #173778;
    }

    @media (max-width: 576px){
        .passwordInput {
            width: 40vw;
        }
    }

</style>