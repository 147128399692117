<template>
	<div class="container mx-auto lg:w-3/4 lg:max-w-klade relative">
		<nav class="pt-4 pb-5 hidden lg:flex lg:justify-between lg:items-end">
			<router-link to="/">
				<img class="w-20" src="@/assets/img/klade-logo.svg" alt="" />
			</router-link>
			<router-link
				:class="{ navItemActive: item.routeName === $route.name }"
				class="font-bebas text-base leading-4 relative navItem"
				:to="{ path: item.slug }"
				v-for="(item, index) in pages"
				v-bind:key="index"
			>
				{{ langG.navItems[index].item }}
			</router-link>
			<LangSelector />
		</nav>
		<div class="py-8 h-20 lg:hidden relative">
			<router-link class="absolute left-8 top-1/2 -translate-y-1/2" to="/">
				<img class="w-20" src="@/assets/img/klade-logo.svg" alt="" />
			</router-link>
			<div @click="menuActive = !menuActive" class="burgerIcon" :class="{ open: menuActive }">
				<div class="burgerIconTop"></div>
				<div class="burgerIconMiddle"></div>
				<div class="burgerIconBottom"></div>
			</div>
			<LangSelector />
			<Transition>
				<div v-if="menuActive" class="mobileMenu">
					<router-link
						@click="closeMenu"
						:class="{ navItemActive: item.routeName === $route.name }"
						class="font-bebas text-2xl block text-center mb-6"
						:to="{ path: item.slug }"
						v-for="(item, index) in pages"
						v-bind:key="index"
					>
						{{ langG.navItems[index].item }}
					</router-link>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script>
import LangSelector from "@/components/LangSelector.vue";

export default {
	data() {
		return {
			menuActive: false,
			pages: [
				{
					id: 1,
					slug: "/new-range",
					routeName: "new-range",
				},
				{
					id: 2,
					slug: "/our-range",
					routeName: "our-range",
				},
				{
					id: 3,
					slug: "/our-story",
					routeName: "our-story",
				},
				{
					id: 4,
					slug: "/our-campaigns",
					routeName: "our-campaigns",
				},
				{
					id: 5,
					slug: "/people",
					routeName: "people",
				},
				{
					id: 6,
					slug: "/contact",
					routeName: "contact",
				},
			],
		};
	},
	name: "PageNav",
	components: {
		LangSelector,
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		getPages() {
			const pages = this.$store.state.pages;
			return pages;
		},
	},
	methods: {
		closeMenu() {
			this.menuActive = false;
		},
	},
};
</script>

<style scoped>
.navItemActive,
.navItem:focus,
.navItem:hover {
	color: #173778;
}

.navItemActive::after,
.navItem:focus::after,
.navItem:hover::after {
	content: "";
	position: absolute;
	bottom: -1vh;
	left: 0;
	background-color: #173778;
	height: 2px;
	width: 100%;
}

.burgerIcon {
	position: absolute;
	top: 50%;
	right: 5%;
	transform: translate(0, -50%);
	width: 40px;
	height: 27px;
}

.burgerIconTop,
.burgerIconMiddle,
.burgerIconBottom {
	position: absolute;
	left: 0;
	width: 100%;
	background-color: #000;
	height: 2px;
	transition: all 1s ease;
}

.burgerIconTop {
	top: 0;
	transform-origin: 0%;
}

.burgerIconMiddle {
	top: 50%;
	transform: translate(0, -50%);
}

.burgerIconBottom {
	bottom: 0;
	transform-origin: 0%;
}

.burgerIcon.open .burgerIconMiddle {
	opacity: 0;
}

.burgerIcon.open .burgerIconTop {
	transform: rotate(39deg);
}

.burgerIcon.open .burgerIconBottom {
	transform: rotate(-39deg);
}

.mobileMenu {
	position: fixed;
	top: 79px;
	z-index: 1;
	background-color: white;
	width: 100%;
	height: calc(100vh - 80px);
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.7s ease-in;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

@media (max-width: 576px) {
	.burgerIcon {
		width: 34px;
		height: 23px;
	}
}
</style>
